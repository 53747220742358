
import CommunicationService from '../../Utilities/CommunicationService/CommunicationService';

export interface IQuizQuestionResponse {
  id?: number,
  questionText?: string,
  replyA?: string,
  replyB?: string,
  replyC?: string,
  replyD?: string,
}

export const getQuestions = async (catNumber: string): Promise<IQuizQuestionResponse[] | null> => {

  const URL = `/api/quizzes/${catNumber}`;

  const response = await CommunicationService.getInstance().secureCall<IQuizQuestionResponse[]>({  
    type:'GET',
    path: URL,
    headers: {
      'pragma': 'no-cache',
      'cache-control': 'no-cache'
    }
  });
  if(response.statusCode === 200 && response.body) { 
    return response.body;
  } 

  return null;
};