import React, { useState } from 'react';
import { ISetAnwsers } from './Interfaces';

const SelectAge = ({handleAnswers, isRequire, questionId, questionText}:ISetAnwsers): JSX.Element => {
  const [ isEmpty, setIsEmpty] = useState<boolean>(true);
  const rate = {start:10, end:20};
  const option = [];

  
  for(let i = rate.start; i <= rate.end ; i++){
    option.push(i.toString());
  }

  const setOptions = option.map((item,index) => (
    <option key={index} value={item}>{item}</option>
  )) ;

  const handleEvent = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setIsEmpty(false); 
    isEmpty ? isRequire && isRequire() : null; 
    handleAnswers(questionId || 999, e.target.value);
  };
  
  return(
    <div className={'singleQuizQuestionItem'} style={{borderColor: `${isEmpty ? 'red' : '#F8EA1D'}`}}>
      <div>
        <span style={{fontWeight:'bold', fontSize:'large'}}>
          {questionText}  <br />
        </span>
      </div>
      <br />
      <div style={{display:'flex', alignContent:'center'}}>      
        <select onChange={handleEvent} 
          style={{width:'50%', margin:'auto', border:'3px solid #abd8e2', fontSize:'large', padding:'0px 15%', fontWeight:'bolder' ,color:'black'}}>
          {setOptions}
        </select>
      </div>
    </div>
  );
};

export default SelectAge;