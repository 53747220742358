

import React from 'react';
import './HeaderText.css';

const HeaderText = ({text}:{text:string | null}):JSX.Element => {
  

  return(
    <div className='text-3d'>
      {text && text.toUpperCase()}
    </div>
  );
};

export default HeaderText;
