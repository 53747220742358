
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import TempImage from '../../Themes/Assets/Images/pinkPanter.jpg';
import nextButton from '../../Themes/Assets/Images/RightArrow.svg';
import H0_Aktionstag from '../../Themes/Assets/Images/H0_Aktionstag.svg';
import { useTranslation } from 'react-i18next';
import { IEvaluationFilled } from '../../Utilities/DbTypes';
import PouchDbService from '../../Utilities/PouchDbService';
import { FrameLoading } from '../../Components/FrameLoading';
import { getAviblity } from './ApiIsActionsDayAvilable';

const  ActivityDay = (): JSX.Element => {  
  const { t } = useTranslation();
  const [ isEvalEnable, setIsEvalEnable ] = useState<boolean>(false);
  const [ isActivityDayEnable, setActivityDayEnable ] = useState<boolean>();
  const [ actionsId, setActionsId ] = useState<number>();
  const [ loading, setLoading ] = useState(true);

  const ActionDayMenu = [
    { name: `${t('menu.names.evaluation')}`, path: '/activityDays/evaluation'},
    { name: `${t('menu.names.actionDay')}`, path: '/activityDays/actionsDay'},
    { name: `${t('menu.names.impressions')}`, path: '/activityDays/impression'}
  ];

  const buttonsStyle = (index: number): React.CSSProperties  => ({
    backgroundColor: `${!isEvalEnable && index === 0  ? 'grey' : !isActivityDayEnable && index === 1  ? 'grey' : 'white'}`, 
    borderColor: `${!isEvalEnable && index === 0  ? '#ff0000aa' : !isActivityDayEnable && index === 1  ? '#ff0000aa' : 'yellow'}` 
  }  );

  let evaluationEnable: IEvaluationFilled;
  const checkIsEnable = async () => {
    setLoading(true);
    const aviblityActivityDay = await getAviblity();
    setActionsId(aviblityActivityDay.body?.eventId);
    setActivityDayEnable(!!aviblityActivityDay.body?.eventId);
    try{
      const evaluationEnable: IEvaluationFilled = await PouchDbService.getInstance().get('evaluationEnable');
      if (evaluationEnable.isFilled) {        
        setIsEvalEnable(false);      
      }
      else{
        setIsEvalEnable(!!aviblityActivityDay.body?.eventId);
      }
    } catch (e) {      
      evaluationEnable = {
        _id: 'evaluationEnable',
        isFilled: false        
      };
      await PouchDbService.getInstance().put(evaluationEnable);
    }
    
    setLoading(false);
  };

  useEffect(() => {
    checkIsEnable();
  }, []);

  const categoriesContent = ActionDayMenu.map((item, index) => (
    <li key={item.name} className="singleMenuItem"  
      style={buttonsStyle(index)}>
      <NavLink className="menuTextDecoration" to={{ 
        pathname: !isEvalEnable && index === 0 || !isActivityDayEnable && index === 1 ? 
          '/activityDays' : 
          item.path, state: { actionsDayId: actionsId }
      }}> 
        <div >
          <div className="upperItemMenuContent" >
            <span className="itemName" style={{fontWeight:'bold',fontSize:'x-large'}}>
              {item.name}
            </span> 
            <img className="nextPageButton" src={nextButton} alt={`Next button of ${item.name}`}/>
          </div>
        </div>
      </NavLink>
    </li>
  ));


  return(
    <FrameLoading isLoading={loading}>
      <div className="quizContainer"> 
        <>
          <div>
            <div>
              <div className={'topBarMenu'}>
                <img src={TempImage} alt="Fact" className={'menuFOTD'}/>    
                <img src={H0_Aktionstag} alt="Sign_Quiz" className={'quizSign'}/>
              </div>
              <div className="quizKatText">
                {t('quiz.categories')}
              </div>
              <ul style={{textAlign:'center', paddingBottom:'1px'}}>
                {categoriesContent}
              </ul>
            </div>
          </div>
        </>      
      </div>
    </FrameLoading>
  );
};

export default withRouter(ActivityDay);