
import CommunicationService from '../../Utilities/CommunicationService/CommunicationService';

interface IResponse {
  statusCode?: number,
  rawBody?: string
}
const URL = '/api/testapp';

export const isSecu = async (): Promise<IResponse> => {
  const result: IResponse = { };
  const response = await CommunicationService.getInstance().secureCall<IResponse>({  
    type:'GET',
    path: URL,
    headers: {
      'pragma': 'no-cache',
      'cache-control': 'no-cache'
    }
  });
  
  if(response.statusCode === 200 ) { 
    result.statusCode = response.statusCode;
  } 
  return result;
};