
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router';
import { FrameLoading } from '../../../Components/FrameLoading';
import ImpressionImage from '../../../Themes/Assets/Images/H0_Eindrucke.svg';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { getImpressionPictures, getOnePicture, IImpressions } from './ApiGetPictures';

const Impression = (): JSX.Element => {
  const [ loading, setLoading ] = useState(false);
  const [ photoIndex, setPhotoIndex ] = useState(0);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ allImages, setAllImages ] = useState<IImpressions[]>();
  const [ images, setImages ] = useState<IImpressions[]>();

  const [ stopLoading, setStopLoading ] = useState<number>();
  const [nodeChange, setChange ] = useState();

  const history = useHistory();
  const { t } = useTranslation();

  const [ pageNumebr, setPageNumber ] = useState<number>(0);
  const [ imageCounter, setImageCounter ] = useState<number>(0);
  const [ imagesTab, setImagesTab ] = useState<string[]>([]);
  const [ imageId,  setImageId ] = useState<number>();
  const [ oneImageSrc, setOneImageSrc ] = useState<string>();

  const observer = useRef(new IntersectionObserver(entries => entries));
  const lastImageElementRef = useCallback(node => {
    if(loading) return;
    if(stopLoading && stopLoading <= imageCounter) return;
    if(observer.current) observer.current;
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting){
        if(allImages !== undefined && images !== undefined) 
          setAllImages(() => [ ...allImages, ...images]);
      }
    });
    if(node) {
      observer.current && observer.current.observe(node);
      setPageNumber(pageNumebr + 1);
      setChange(node);
    }
  },
  [nodeChange],
  );

  const getPictures = async () => {
    setLoading(true);
    const resp = await getImpressionPictures();
    if(stopLoading && stopLoading <= imageCounter) setImages(resp.body?.data);
    if(pageNumebr === 0) {
      setAllImages(resp.body?.data);
      setStopLoading(resp.body?.dataCount);
    }
    setLoading(false);
  };

  const buttonStyle: React.CSSProperties = {    
    width:'50%', 
    height:'20%',
    color: '#0e627d',
    backgroundColor:'#E6F4F8',
    padding: '5px',
    fontWeight:'bold',
    appearance: 'none',
    border:'none'
  };

  useEffect(() => {
    getPictures();
    setImageCounter(allImages !== undefined ? allImages?.length : 0);
    setImageTab();

  }, [pageNumebr]);


  const setImageTab = () => {
    if(allImages){
      allImages.forEach((item) => {
        setImagesTab((oldArr) => oldArr.concat( item.imageThumbnail !== undefined ? item.imageThumbnail : 'Null'));
      });
    }
  };

  


  const generateKnowledgeStockCategories = () => {
    if (allImages) {
      return (
        <>
          {                       
            allImages.map((item,index) => (         
              allImages.length === index + 1 ? 
                <div key={index + 1} ref={lastImageElementRef} style={{overflow:'auto', maxHeight:'90px', padding:'0px 5px'}}>    
                  <img 
                    style={{width:'100%',
                      marginBottom:'10px'}} 
                    onClick={() => {
                      setPhotoIndex(index); 
                      setImageId(item.id);
                      setIsOpen(true);
                    }} 
                    src={item.imageThumbnail} 
                    alt={item.id?.toString()} 
                  />  
                         
                </div>
                :
                <div key={index + 1} style={{overflow:'auto', maxHeight:'90px', padding:'0px 5px'}}>          
                  <img 
                    style={{width:'100%',
                      marginBottom:'10px'}} 
                    onClick={() => {
                      setPhotoIndex(index); 
                      setImageId(item.id);
                      setIsOpen(true);
                    }} 
                    src={item.imageThumbnail} 
                    alt={item.id !== undefined ? item.id.toString() : index.toString()} 
                  />              
                  {/* {setImageCounter(imageCounter + 1)} */}
                </div>
            ))
          }
        </>
      );
    }
    return <div></div>;
  };

  const getOneImage = async (imageId: number) => {
    setLoading(true);
    const resp = await getOnePicture(imageId);
    setOneImageSrc(resp.body?.image);
    setLoading(false);
  };

  useEffect(() => {
    if(imageId !== undefined) getOneImage(imageId);
  },[isOpen, imageId]);

  return (
    <div className="quizContainer"> 
      <FrameLoading isLoading={loading}>
        <>
          <div className={'topBarMenu'}>
            <img src={ImpressionImage} alt="Fact" style={{padding:'40px 0px 30px'}}/>    
          </div>
          <div >
            <div style={{display:'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px'}} >
              {generateKnowledgeStockCategories()}
            </div>
            <div style={{display:'flex', justifyContent:'flex-end', padding:'0px 20px 15px 0px'}}>
              <button style={ buttonStyle } onClick={() => history.push('/activityDays')} >
                {t('activityDay.goCat')} 
              </button>
            </div>
          </div>
          
          {isOpen && (
            <Lightbox
              mainSrc={oneImageSrc !== undefined ? oneImageSrc : ''}
              nextSrc={imagesTab[(photoIndex + imagesTab.length + 1) % imagesTab.length]}
              prevSrc={imagesTab[(photoIndex + imagesTab.length - 1) % imagesTab.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() => setImageId(imageId !== undefined ? imageId == allImages?.length ? 1 : imageId + 1 : 1)}
              onMoveNextRequest={() => setImageId(imageId !== undefined ? imageId - 1 == 0 ? allImages?.length : imageId - 1 : allImages?.length) }
            />
          )}
        </>
      </FrameLoading>
    </div>
  );
};

export default withRouter(Impression);