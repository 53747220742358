import React, { ReactElement } from 'react';
import Loading from 'react-loading-animation';

interface FrameLoadingProps {
  isLoading: boolean;
  children: ReactElement;
  className?: string;
}

export const FrameLoading: React.FC<FrameLoadingProps> = ({
  isLoading,
  children,
  className,
}: FrameLoadingProps) => {

  const styleIsLoading: React.CSSProperties = {
    position: 'absolute',
    justifyContent: 'center',
    background: '#ccccccaa',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '100%',
    zIndex: 999,
    left: 0,
    top: 0,
  };

  return (
    <div className={className} style={{}}>
      {children}
      {isLoading && (         
        <div style={styleIsLoading}>
          <Loading/>
        </div>
        
      )}
    </div>
  );
};