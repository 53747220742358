
import React from 'react';
import TempImage from '../../../Themes/Assets/Images/H0_Check_Yourself.svg';
import { useHistory, withRouter } from 'react-router';
import nextButton from '../../../Themes/Assets/Images/RightArrow.svg';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const CheckYourself = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  
  const checkYourDrinking = 'https://www.drugcom.de/tests/selbsttests/check-your-drinking-wie-riskant-ist-dein-alkoholkonsum/test';
  const changeYOurDrinking = 'https://www.drugcom.de/beratung-finden/change-your-drinking-beratung-und-hilfe-zur-reduzierung-des-alkoholkonsums/anmeldung/';

  const cat = [{contentName:t('firstAid.isAlkoholProblem'), path:'evaluation'},
    {contentName:t('firstAid.checkDrinking'), link: checkYourDrinking },
    {contentName:t('firstAid.changeDrinking'), link: changeYOurDrinking}];

  const buttonStyle: React.CSSProperties= {    
    width:'50%', 
    height:'20%',
    color: '#0e627d',
    backgroundColor:'#E6F4F8',
    padding: '5px',
    fontWeight:'bold',
    appearance: 'none',
    border:'none'
  };
  
  const openInNewWindow = (link:string) => {
    window.open(link, 'width=200,height=100');
  };


  const generateKnowledgeStockCategories = () => {
    if (cat) {
      return (
        <>
          {                       
            cat.map((item,index) => (            
              <div key={index + 1}  className='singleQuizCategoriesItem'>                
                {item.path ? <NavLink className="menuTextDecoration" to={item.path} >     
                  <div>           
                    <div className='upperItemMenuContent' 
                      style={{padding:'10px', fontWeight:'bolder', fontSize:'large', textAlign:'center'}}>                                
                      <span style={{width:'80%'}}>
                        {item.contentName.toUpperCase()}
                      </span> 
                      <img className="nextPageButton" src={nextButton} alt={`Next button of ${item.contentName}`}/>
                    </div>
                  </div>
                </NavLink> : item.link &&
                  <div onClick={() => openInNewWindow(item.link)}>            
                    <div className='upperItemMenuContent' 
                      style={{padding:'10px', fontWeight:'bolder', fontSize:'large', textAlign:'center'}}>                                
                      <span style={{width:'80%'}}>
                        {item.contentName.toUpperCase()}
                      </span> 
                      <img className="nextPageButton" src={nextButton} alt={`Next button of ${item.contentName}`}/>
                    </div>
                  </div>
                }
              </div>         
            ))
          }
        </>
      );
    }
    return <div></div>;
  };

  return (
    <div className="quizContainer"> 
      <div>
        <>
          <div className={'topBarMenu'}>
            <img src={TempImage} alt="Fact" style={{padding:'40px 0px 30px'}}/>    
          </div>
          <div style ={{paddingLeft:'30px',width:'90%'}}>
            <span style={{ color:'white', fontWeight:'bold'}}>
              {(t('firstAid.checkYourselfHeader')).toUpperCase()}
            </span> 
          </div>
          <ul >
            {generateKnowledgeStockCategories()}
          </ul>
          <div style={{justifyContent:'flex-end', display:'flex', paddingRight:'20px'}}>
            <button style={ buttonStyle } onClick={() => history.push('/menu')} >
              {t('activityDay.goCat')} 
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default withRouter(CheckYourself);
