import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  RouteComponentProps,
  StaticContext,
  useHistory,
  withRouter,
} from 'react-router';
import { NavLink } from 'react-router-dom';
import CloseButton from '../../../Themes/Assets/Images/Button_Close.svg';
import Logo from '../../../Themes/Assets/Images/Logo_SKB.svg';
import TempImage from '../../../Themes/Assets/Images/tempImage.png';
import { IArtticlesList } from './ApiGetListOfArticles';

const SingleArticle = ({
  location,
}: RouteComponentProps<
  { [key: string]: string },
  StaticContext,
  { itemContent: IArtticlesList }
>): JSX.Element => {
  const history = useHistory();
  const handleImage = (data: string) => `${data}`;

  const itemContent = location.state.itemContent;
  const { t } = useTranslation();

  const imageGetted = () => {
    if (itemContent?.image !== undefined && itemContent?.image !== null) {
      return itemContent?.image;
    }
    return TempImage;
  };

  const hashTagTab = itemContent?.hashtag
    ?.replace(RegExp(' ', 'g'), '')
    .split('#');

  hashTagTab?.shift();
  console.log(hashTagTab);
  const linkTab = itemContent?.externalLink?.split(' ');

  const openInNewWindow = (link: string) => {
    window.open(link, 'width=200,height=100');
  };

  const showLinks = () =>
    linkTab?.map((item, index) => {
      return (
        <a key={index} onClick={() => openInNewWindow(item)}>
          {item.slice(0, 30) + '... '}
        </a>
      );
    });

  function b64EncodeUnicode(str: string) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
      })
    );
  }

  const generateKnowledgeStockArticle = () => (
    <div className="singleQuizCategoriesItem">
      <div>
        <span style={{ fontWeight: 'bolder' }}>
          {t('knowledgeStock.fact')} {itemContent?.id}
        </span>
        <div style={{ fontWeight: 'bolder' }}>
          <span>
            {t('facts.didYouKnow')}
            {itemContent?.factText}
            <br />
            <br />
          </span>
        </div>
        <span>{itemContent?.articleText}</span>
        <span style={{ fontSize: 'small', fontWeight: 'bold' }}>
          <br />
          <br />
          {hashTagTab?.map((item) => {
            const link = b64EncodeUnicode(item);
            return (
              <NavLink
                to={{
                  pathname: `/categories/${link}`,
                  state: { categoryImage: 'none' },
                }}
                key={item}
                style={{ textDecoration: 'none' }}
              >
                {`#${item} `}
              </NavLink>
            );
          })}
        </span>
        <span>
          <br />
          {showLinks()}
        </span>
      </div>
    </div>
  );

  return (
    <div className="factContainer">
      <div className="factTopBar">
        <img src={Logo} onClick={() => history.push('/menu')} alt="Logo" />
      </div>
      <div className="factContent">
        <div>
          <>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                width: '100%',
                height: '35vh',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#000000aa',
              }}
            >
              <img
                src={handleImage(imageGetted())}
                style={{ maxHeight: '100%', maxWidth: '100%' }}
              />
              <img
                src={CloseButton}
                alt="Close Button"
                onClick={history.goBack}
                style={{ position: 'absolute', top: 15, right: 15 }}
              />
            </div>
            <ul>{generateKnowledgeStockArticle()}</ul>
          </>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SingleArticle);
