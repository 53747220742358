import React, { useEffect, useState } from 'react';
import SignOfAddiction from '../../Themes/Assets/Images/H0_Anzeichen_Sucht.svg';
import { FrameLoading } from '../../Components/FrameLoading';
import { useHistory, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getSymptoms, ISymptomsList } from './ApiGetSymptoms';

const ActionsDay = (): JSX.Element => {
  const [ loading, setLoading ] = useState(false);
  const [ symptoms, setSymptoms ] = useState<ISymptomsList[] | null>();
  const history = useHistory();
  const { t } = useTranslation();

  const buttonStyle: React.CSSProperties= {    
    width:'50%', 
    height:'20%',
    color: '#0e627d',
    backgroundColor:'#E6F4F8',
    padding: '5px',
    fontWeight:'bold',
    appearance: 'none',
    border:'none'
  };

  const getListOfSymptoms = async () => {
    setLoading(true);
    const resp = await getSymptoms();
    setSymptoms(resp.body?.data);
    setLoading(false);
  };

  useEffect(() => {
    getListOfSymptoms();
  }, []);

  const generateKnowledgeStockCategories = () => {
    if (symptoms) {
      return (
        <>
          {                       
            symptoms.map((item,index) => (            
              <div key={index}  >                
                <div>                  
                  <div className='singleQuizCategoriesItem' >     
                    <b>{index + 1 }</b> <br/>                
                    <span>
                      {item.symptomText}
                    </span> 
                  </div>
                </div>
              </div>         
            ))
          }
        </>
      );
    }
    return <div></div>;
  };


  return (
    <div className="quizContainer"> 
      <FrameLoading isLoading={loading}>
        <>
          <div className={'topBarMenu'}>
            <img src={SignOfAddiction} alt="Fact" style={{padding:'40px 0px 30px'}}/>    
          </div>
          <div style ={{paddingLeft:'30px',width:'60%'}}>
            <span style={{ color:'#F8EA1D', fontWeight:'bold'}}>
              {(t('firstAid.headerSignOfAddiction')).toUpperCase()}
            </span> 
          </div>
          <ul >
            {generateKnowledgeStockCategories()}
          </ul>
          <div style={{justifyContent:'flex-end', display:'flex', paddingRight:'20px'}}>
            <button style={ buttonStyle } onClick={() => history.push('/firstAid')} >
              {t('activityDay.goCat')} 
            </button>
          </div>
        </>
      </FrameLoading>
    </div>
  );
};

export default withRouter(ActionsDay);
