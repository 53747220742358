
import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import DailyContent from './DailyContent';
import Logo from '../../Themes/Assets/Images/Logo_SKB.svg';
import './FactOfTheDay.css';


const FactOfTheDay = (): JSX.Element => { 
  const history = useHistory();

  return(
    <div className='factContainer'>  
      <div className="factTopBar"> 
        <img src={Logo} onClick={() => history.push('/menu')} alt="Logo" />
      </div>
      <div className="factContent">     
        <DailyContent/>
      </div>
    </div>
  );
};

export default withRouter(FactOfTheDay);
