
import React, { useState } from 'react';
import './MainTheme.css';
import { Page } from '../Components/Page';
import { RouterProvider, routePath} from './Providers/RouterProvider';
import { isIOS, isMobileOnly } from 'mobile-device-detect';
import { InputContext } from './Providers/InputContext';
import { Link } from 'react-router-dom';
import { ReactComponent as MenuStart} from './Assets/Images/Icon_Start.svg';
import { ReactComponent  as MenuQuiz } from './Assets/Images/Icon_Quiz.svg';
import { ReactComponent as MenuWissens} from './Assets/Images/Icon_Knowledge_stock.svg';
import { ReactComponent as MenuRanking} from './Assets/Images/Icon_Ranking.svg';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const MainTheme = (): JSX.Element => {
  const [isInputActive, setIsInputActive] = useState(false);
  const [qrState, setQrState] = useState('');
  const [questionNumber,setQuestionNumber] = useState<number>(1);

  const toggleActiveState = (props:boolean) => setIsInputActive(props);
  const toggleQrState = (props:string) => setQrState(props);
  const toggleQuestionNumber = (props:number) => setQuestionNumber(props);

  const location = useLocation();

  
  const { t } = useTranslation();
  const pathname = window.location.pathname;
  return(
    <RouterProvider.Provider value={{
      welcomePage: routePath.welcomePage,
      scanner: routePath.scanner,
      menu: routePath.menu,
      quiz: routePath.quiz,
      activityDays: routePath.activityDays,
      help: routePath.menu,
      socialWall: routePath.menu,
      factOfTheDay: routePath.factOfTheDay,
      activityDaysEvaluation: routePath.activityDaysEvaluation,
      actionsDay: routePath.actionsDay,
      thanksPage: routePath.thanksPage,
      quizQA: routePath.quizQA,
      knowledgeStockArticles: routePath.knowledgeStockArticles,
      knowledgeStockSingleArticle: routePath.knowledgeStockSingleArticle,
      knowledgeStock: routePath.knowledgeStock,
      scoresPage: routePath.scoresPage,
      firstAid: routePath.firstAid,
      signOfAddiction: routePath.signOfAddiction,
      checkYourself: routePath.checkYourself,
      checkYourselfEvaluation: routePath.checkYourselfEvaluation,
      contact: routePath.contact,
      impression: routePath.impression
    }}>
      <InputContext.Provider value={{ isInputActive, qrState, questionNumber, toggleActiveState, toggleQrState, toggleQuestionNumber}}>
        <div className={isMobileOnly ? (isInputActive && !isIOS) ? 'outerContainerKeyboard' : 'outerContainer' : 'outerContainerDesktop' } style={{justifyContent:`${pathname === '/scanner' ? 'center' : 'space-between'}`}}>
        
          {                    
            <Page/>                    
          } 
          {
            (location.pathname != '/'  && location.pathname != '/scanner' ) && ( isMobileOnly || isIOS) ? 
              <div className="menuFotter">
                <Link to="/menu" >
                  <div className="menuDiv">
                    <MenuStart />
                    <span className="caption">
                      {t('footer.menu')}
                    </span>
                  </div>
                </Link>
                <Link to="/quiz">
                  <div className="menuDiv">
                    <MenuQuiz />
                    <span className="caption">
                      {t('footer.quiz')}
                    </span>
                  </div>
                </Link>  
                <Link to="/categories">
                  <div className="menuDiv">
                    <MenuWissens />
                    <span className="caption">
                      {t('footer.knowledgeStock')}
                    </span>
                  </div>
                </Link>
                <Link to="/score">
                  <div className="menuDiv">
                    <MenuRanking />
                    <span className="caption">
                      {t('footer.ranking')}
                    </span>
                  </div>
                </Link>
              </div>: null
          }
        </div>
      </InputContext.Provider>
    </RouterProvider.Provider>

  );
};
