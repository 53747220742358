
import React, { useEffect, useState, useContext } from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { Redirect, withRouter, useHistory } from 'react-router-dom';
import { InputContext } from '../../Themes/Providers/InputContext';
import './Scanner.css';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Tooltip from '../../Themes/Assets/Images/IconErsteHilfe.svg';
import qrCode from '../../Themes/Assets/Images/qrCode.png';

const Scanner = () =>  {
  const [data, setData] = useState('');
  const { toggleQrState } = useContext(InputContext);
  const { t } = useTranslation();
  const LoginToken = localStorage.getItem('X-Auth-Token');
  const history = useHistory();

  useEffect(() => {
    toggleQrState(data);
    if(LoginToken){
      history.push('/menu');
    }
  }, [ data, toggleQrState, LoginToken]);
      

  if(data !== ''){    
    return <Redirect to='/' />;    }
  else
    return(
      <>
        <div className="hintScannerContainer">
          <p data-tip={t('qr.text')}>
            <img src={Tooltip} alt={t('qr.text')} className="imageScannerQuestion" style={{ width: '20vw', height: '10vh' }}/>
          </p>
          <ReactTooltip className="tooltip" place="bottom" effect="solid" />     
        </div>
        <div className='scannerContainer'>
          <BarcodeScannerComponent
            width='100%'
            height='100%'
            torch={false}
            onUpdate={(err, result) => {
              if (result){
                if(result.getText()) {
                  setData(result.getText());         
                }
              }           
            }}
          />      
          <img src={qrCode} className='insideScanner' alt="Alt" />
        </div>
      </>
    );
};

export default withRouter(Scanner);


