
import React, { useEffect, useState } from 'react';
import CheckYourself from '../../../Themes/Assets/Images/H0_Check_Yourself.svg';
import { useHistory, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getEvaluation, ICheckYourself } from './ApiGetEvaluation';
import { FrameLoading } from '../../../Components/FrameLoading';
import Button from './Button';
import YouDontHaveProblem from './LastPages/YouDontHaveProblem';
import YouHaveProblem from './LastPages/YouHaveProblem';

const FirstAidEvaluation = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const [ loading, setLoading ] = useState(true);
  const [ survey, setSurvey ] = useState<ICheckYourself[] | null>();
  const [ answer, setAnswer ] = useState<{questionId: number, answer: string}[]>([]);
  const [ problem, setProblem ] = useState<number>(0);
  const [ isValid, setIsvalid ] = useState<boolean>(true);
  const [ isRequire, setIsRequire ] = useState<number>();

  const buttonStyle: React.CSSProperties= {    
    width:'40%', 
    height:'20%',
    color: '#0e627d',
    padding: '5px',
    backgroundColor:'#E6F4F8',
    fontWeight:'bold',
    marginBottom:'10px',
    appearance: 'none',
    border:'none'
  };

  const popUpStyle: React.CSSProperties={
    backgroundColor:'#1559', 
    width:'100%', 
    height:'105%', 
    margin: 0, 
    padding: 0, 
    position:'fixed', zIndex:999,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center', 
    alignItems:'center'
  };

  const updateItem = (questId: number, answerText: string) => {
    const objIndex = answer.findIndex((obj => obj.questionId == questId));
    const g = answer[objIndex];
    const data = { 'questionId': questId, 'answer': answerText };    
    if(answerText !== 'None'){
      if (objIndex === -1){
        setAnswer( () => [...answer, data] );
      }
      else{
        g['answer'] = answerText;
        setAnswer([
          ...answer.slice(0,objIndex),
          g,
          ...answer.slice(objIndex+1)
        ]
        );
      }}
  };

  const getSurveyForEvaluation = async () => {
    setLoading(true);
    const resp = await getEvaluation();
    setSurvey(resp.body?.data);    
    setLoading(false);
  };

  const handleIsRequire = () => {
    setIsRequire(isRequire !== undefined ? isRequire + 1 : 1);
  };

  const generateKnowledgeStockCategories = () => {
    if (survey) {
      return (
        <>
          {                       
            survey.map((item,index) => (
              <Button key={index} 
                handleAnswers={updateItem} 
                questionId={item.id} 
                questionText={item.questionText} 
                isRequire={handleIsRequire}  />
            ))
          }
        </>
      );
    }
    return <div></div>;
  };

  useEffect(() => {
    getSurveyForEvaluation();
  }, []);


  let counter = 0;
  const confirmCoumnt = (list: {questionId: number, answer: string}[]) => {
    let i = 0;
    for(i; i < list.length; i++ ){
      if(list[i].answer === 'JA') {counter ++;}
    }
    return counter;
  };
  return (
    
    <div className="quizContainer"> 
      <FrameLoading isLoading={loading}>
        <div>
          { problem === 0 ? 
            <>
              { !isValid ? <div onClick={() => setIsvalid(true)} style={popUpStyle}>
                <div className='singleQuizQuestionItem' 
                  style={{width:'70%',
                    height: '20%', 
                    display:'flex', 
                    flexDirection:'column', 
                    textAlign:'center', 
                    alignItems:'center', 
                    justifyContent:'center', 
                    fontWeight:'bolder', 
                    fontSize:'x-large', }}>
                  < span >
                    {t('firstAid.popUp')}
                  </span>
                </div>
                  
              </div> : null}
              <div className={'topBarMenu'}>
                <img src={CheckYourself} alt="Fact" style={{padding:'40px 0px 30px'}}/>    
              </div>
              <div style ={{paddingLeft:'30px', width:'90%'}}>
                <span style={{ color:'#F8EA1D', fontWeight:'bold'}}>
                  {(t('firstAid.isAlkoholProblem')).toUpperCase()}
                </span> 
              </div>
              <ul >
                {generateKnowledgeStockCategories()}
                <div style={{justifyContent: 'space-around',  display:'flex'}}>
                  <button style={ buttonStyle } 
                    onClick={history.goBack}>
                    {t('quiz.return')} 
                  </button>
                  <button style={ buttonStyle } onClick={() => {
                    if(isRequire !== survey?.length){
                      setIsvalid(false);
                    } else {
                      if(confirmCoumnt(answer) < 2){
                        setProblem(2);
                      } else {
                        setProblem(1);
                      }
                    }}
                  } >
                    {t('quiz.next')} 
                  </button>
                </div>            
              </ul> 
            
            </> : problem === 1 ? 
              <YouHaveProblem/> 
              : 
              <YouDontHaveProblem/>
          }
          
        </div>
      </FrameLoading>
    </div>
  );
};

export default withRouter(FirstAidEvaluation);
