
import React, { useEffect, useState } from 'react';
import TempImage from '../../../Themes/Assets/Images/H0_Aktionstag.svg';
import { FrameLoading } from '../../../Components/FrameLoading';
import { useHistory, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getEventInformation, IEventInformation } from './ApiGetEventInformation';
import { IResponse } from '../../../Utilities/CommunicationService/CommunicationService';

const ActionsDay = (): JSX.Element => {
  const [ loading, setLoading ] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [ eventInfo, setEventInfo ] = useState<IResponse<IEventInformation>>();

  const cat = [{id: 1, categoryName: 'TAGESABLAUF', categoryContent: eventInfo?.body?.actionDaySchedule},
    {id: 2, categoryName: 'ZIELE', categoryContent: eventInfo?.body?.actionDayGoals}];

  const buttonStyle: React.CSSProperties = {    
    width:'50%', 
    height:'20%',
    color: '#0e627d',
    padding: '5px',
    fontWeight:'bold',
    backgroundColor:'#E6F4F8',
    appearance: 'none',
    border:'none'
  };

  const getEventInfo = async () => {
    setLoading(true);
    const resp = await getEventInformation(); 
    setEventInfo(resp);
    setLoading(false);
  };

  useEffect(() => {
    getEventInfo();    
  }, []);

  const generateKnowledgeStockCategories = () => {
    if (cat) {
      return (
        <>
          {                       
            cat.map((item,index) => (            
              <div key={index + 1}   style={{width:'100vw'}}>                
                <div>
                  <span style={{paddingLeft:'30px', color:'#F8EA1D', fontWeight:'bold'}}>
                    {item.categoryName?.toUpperCase()}
                  </span> 
                  <div className='singleQuizCategoriesItem'>                     
                    <span>
                      {item.categoryContent}
                    </span> 
                  </div>
                </div>
              </div>         
            ))
          }
        </>
      );
    }
    return <div></div>;
  };


  return (
    <div className="quizContainer"> 
      <FrameLoading isLoading={loading}>
        <>
          <div className={'topBarMenu'}>
            <img src={TempImage} alt="Fact" style={{padding:'40px 0px 30px'}}/>    
          </div>
          <ul >
            {generateKnowledgeStockCategories()}
          </ul>
          <div style={{justifyContent:'flex-end', display:'flex', paddingRight:'20px'}}>
            <button style={ buttonStyle } onClick={() => history.push('/activityDays')} >
              {t('activityDay.goCat')} 
            </button>
          </div>
        </>
      </FrameLoading>
    </div>
  );
};

export default withRouter(ActionsDay);
