

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FrameLoading } from '../../Components/FrameLoading';
import { getCategories, IQuizCategoryResponse } from '../Quiz/ApiGetCategories';
import HeaderImage from '../../Themes/Assets/Images/pinkPanter.jpg';
import Knowledge from '../../Themes/Assets/Images/H0_Wissen.svg';
import { useTranslation } from 'react-i18next';

const KnowledgeContent = (): JSX.Element => {
  const [ cat, setCat ] = useState<IQuizCategoryResponse[]>();
  const [ loading, setLoading ] = useState(true);
  const { t } = useTranslation();

  const getAllCategoriesForKS = async () => {
    setLoading(true);
    const resp = await getCategories();    
    if(resp !== null) {
      setCat(resp.body?.data); 
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllCategoriesForKS();
  }, []);

  const generateKnowledgeStockCategories = () => {
    if (cat) {
      
      return (
        <>
          <li key='0' className='singleQuizCategoriesItem' >
            <NavLink className="menuTextDecoration"  
              to={{ pathname: '/factOfTheDay'}}>
              <div>
                <div>                     
                  <span>
                    {t('knowledgeStock.factOfTheDay')}
                  </span> 
                </div>
              </div>
            </NavLink>
          </li>
          {                       
            cat.map((item,index) => (            
              <li key={index + 1} className='singleQuizCategoriesItem' >
                <NavLink className="menuTextDecoration"  
                  to={{ pathname: `/categories/${item.id}`, state: { categoryName: item.categoryName, categoryImage: item.image}}} >
                  <div>
                    <div >                     
                      <span>
                        {item.categoryName?.toUpperCase()}
                      </span> 
                    </div>
                  </div>
                </NavLink>
              </li>         
            ))
          }
        </>
      );
    }
    return <div></div>;
  };


  return (
    <div className="quizContainer"> 
      <FrameLoading isLoading={loading}>
        <>
          <div className={'topBarMenu'}>
            <img src={HeaderImage} alt="Fact" className={'menuFOTD'}/>    
            <img src={Knowledge} alt="Fact" style={{padding:'10px 10px ', position:'absolute', top:0, left:0}}/>    
            <span className={'faktOfTheDayGradient'} 
              style={{textAlign:'left',
                backgroundImage:'linear-gradient( #00000000,black)', 
                width:'auto', 
                paddingLeft:'6%',
                paddingBottom: '2%' }}>                  
              <span style={{width:'80%', padding:'0', position:'relative', left:'-5%'}}>
                {t('knowledgeStock.menuSign')}
              </span> 
            </span>
          </div>
          <ul style={{fontWeight:'bolder', textAlign:'center', fontSize: 'large'}}>
            {generateKnowledgeStockCategories()}
          </ul>
        </>
      </FrameLoading>
    </div>
  );
};

export default KnowledgeContent;
