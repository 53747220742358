
import CommunicationService, { IPaginableResponse, IResponse } from '../../../Utilities/CommunicationService/CommunicationService';

export interface IArtticlesList {
  id?: number,
  articleText?: string,
  factText?:string,
  questionText?: string | null,
  hashtag?: string,
  image?: string,
  externalLink?: string,
  isActive?: boolean,
}


export const getListOfArticles = async (categoryId:string): Promise<IResponse<IPaginableResponse<IArtticlesList>>> => {
  const URL = `/api/articles/${categoryId}`;
  const response = await CommunicationService.getInstance().secureCall<IPaginableResponse<IArtticlesList>>({  
    type:'GET',
    path: URL,
    headers: {
      'cache-control': 'no-cache'
    },
    body: {
      'page': '0'
    }
  });
  if(response.error){
    console.log(response.error);
  }  
  return response; 
};