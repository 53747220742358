import CommunicationService, { IResponse } from '../../../Utilities/CommunicationService/CommunicationService';

export interface IEventInformation {
 actionDaySchedule?: string,
 actionDayGoals?: string
}

export const getEventInformation = async (): Promise<IResponse<IEventInformation>> => {
  const URL = '/api/event/information';
  const response = await CommunicationService.getInstance().secureCall<IEventInformation>({  
    type:'GET',
    path: URL,
    headers: {
      'cache-control': 'no-cache'
    }
  });
  if(response.error){
    console.log(response.error);
  }
  
  return response;
 
};