
import React, { useEffect, useState } from 'react';
import {ISetAnwsers} from './Interfaces';

const RadioButtonField = ({handleAnswers, isRequire, questionId, questionText, answerOption}:ISetAnwsers): JSX.Element => {
  const [ activeAnwser, setActiveAnwser ] = useState<string>('None');
  const [ isEmpty, setIsEmpty] = useState<boolean>(true);
  const [ isPossible, setIsPossible] = useState<boolean>(true);

  const sampleQuestions = answerOption ? answerOption?.split(';') : [];

  const radioCreate =
  sampleQuestions.map((item,index) => (
    <label key={index}> 
      <input  type='radio' value={item} name={`question_${questionId}`}/> {item} <br />
    </label>
  ));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> ) => {
    setActiveAnwser(event.target.value);
    setIsEmpty(false);  
    setIsPossible(false);
    if(isPossible) isRequire && isRequire();  
  };

  useEffect(() => {
    handleAnswers(questionId || 999, activeAnwser);
  }, [activeAnwser]);

  return(
    <div className={'singleQuizQuestionItem'} style={{borderColor: `${isEmpty ? 'red' : '#F8EA1D'}`}}>
      <div>
        <span style={{fontWeight:'bold', fontSize:'large'}}>
          {questionText}  <br />
        </span>  
      </div>
      <br />
      <div style={{display:'block' }} onChange={handleChange}>      
        {radioCreate}
      </div>
    </div>
  );
};

export default RadioButtonField;