/* eslint-disable @typescript-eslint/no-explicit-any */

import { IEvaluationFilled, ILimitTries } from './DbTypes';
import PouchDbService from './PouchDbService';

(window as any).resetDailyLimit = async () => {
  const triesLimit: ILimitTries = await PouchDbService.getInstance().get('triesLimit'); 
  triesLimit.limitReacedDate = 0;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  triesLimit._rev = triesLimit._rev!; 
  triesLimit.played = 0;
  await PouchDbService.getInstance().put(triesLimit);
  console.log('%c Daily limit has been reset!','background: #bada55; color: #222');
};

(window as any).resetEvaluation = async () => {
  const evaluationEnable: IEvaluationFilled = await PouchDbService.getInstance().get('evaluationEnable'); 
  evaluationEnable.isFilled = false;
  await PouchDbService.getInstance().put(evaluationEnable);
  console.log('%c Evaluation has been reset!','background: #bada75; color: #252');
};