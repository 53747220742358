import React, { useEffect, useState } from 'react';
import { FrameLoading } from '../../Components/FrameLoading';
import {
  getListOfArticles,
  IArtticlesList,
} from './Articles/ApiGetListOfArticles';
import TempImage from '../../Themes/Assets/Images/tempImage.png';
import CloseButton from '../../Themes/Assets/Images/Button_Close.svg';
import Logo from '../../Themes/Assets/Images/Logo_SKB.svg';
import {
  RouteComponentProps,
  StaticContext,
  useHistory,
  useParams,
  withRouter,
} from 'react-router';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import HeaderText from '../../Utilities/HeaderText';

interface ParamTypes {
  id: string;
}

const b64DecodeUnicode = (str: string) => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

const KnowledgeStockArticles = ({
  location,
}: RouteComponentProps<
  { [key: string]: string },
  StaticContext,
  { categoryName: string; categoryImage: string }
>): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState<IArtticlesList[] | null>();
  const [header, setHeader] = useState<string>();
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { t } = useTranslation();
  const handleImage = (data: string) => `${data}`;
  const imageGetted = () => {
    if (location.state.categoryImage) {
      if (
        location.state.categoryImage !== undefined &&
        location.state.categoryImage !== null
      ) {
        return location.state.categoryImage;
      }
    }
    return TempImage;
  };

  const getArticlesForCategory = async (categoryId: string) => {
    setLoading(true);
    const resp = await getListOfArticles(categoryId);
    if (id.length > 3) {
      setHeader(`#${b64DecodeUnicode(id)}`);
    } else {
      setHeader(location.state.categoryName);
    }

    setArticles(resp.body?.data);
    setLoading(false);
  };

  useEffect(() => {
    getArticlesForCategory(id);
  }, []);

  const generateKnowledgeStockArticles = () =>
    articles &&
    articles.map((item, index) => (
      <li key={index} className="singleQuizCategoriesItem">
        <NavLink
          className="menuTextDecoration"
          to={{
            pathname: `/categories/${id}/${item.id}`,
            state: { itemContent: item },
          }}
        >
          <div>
            <span style={{ fontWeight: 'bolder' }}>
              {t('knowledgeStock.fact')} {item.id}
            </span>
            <div style={{ fontWeight: 'bolder' }}>
              <span>{t('facts.didYouKnow')}</span>
            </div>
            <span>{item.factText}</span>
          </div>
        </NavLink>
      </li>
    ));

  return (
    <div className="factContainer">
      <div className="factTopBar">
        <img src={Logo} onClick={() => history.push('/menu')} alt="Logo" />
      </div>
      <div className="factContent">
        <FrameLoading isLoading={loading}>
          <>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                width: '100%',
                height: '35vh',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#000000aa',
              }}
            >
              {handleImage(imageGetted()) !== 'none' ? (
                <img
                  src={handleImage(imageGetted())}
                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                />
              ) : null}
              <img
                src={CloseButton}
                alt="Close Button"
                onClick={history.goBack}
                style={{ position: 'absolute', top: 15, right: 25 }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '30%',
                  left: 0,
                  maxWidth: '100%',
                }}
              >
                <HeaderText text={header || null} />
              </div>
            </div>
            <ul>{generateKnowledgeStockArticles()}</ul>
          </>
        </FrameLoading>
      </div>
    </div>
  );
};

export default withRouter(KnowledgeStockArticles);
