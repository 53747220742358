import CommunicationService, { IResponse } from '../../../Utilities/CommunicationService/CommunicationService';

export interface IZipCodeSearch {
  location: string
}


export const getZipCode = async (zipCode: string): Promise<IResponse<IZipCodeSearch>> => {
  const URL = `/api/help/contacts/${zipCode}`;
  const response = await CommunicationService.getInstance().secureCall<IZipCodeSearch>({  
    type:'GET',
    path: URL,
    headers: {
      'cache-control': 'no-cache'
    }
  });
  if(response.error){
    console.log(response.error);
  }
  
  return response;
 
};
