import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterProvider } from '../Themes/Providers/RouterProvider';

function Status({ code, children }:{code:number, children:JSX.Element}) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = code;
        return children;
      }}
    />
  );
}
function NotFound() {
  return (
    <Status code={404}>
      <div>
        <h1>Sorry, can’t find that.</h1>
      </div>
    </Status>
  );
}



export const Page = (): JSX.Element  => {
  return (
    <RouterProvider.Consumer>
      {
        (path) => (
          <Switch>
            {path.welcomePage},
            {path.menu},
            {path.scanner},
            {path.quiz},
            {path.activityDays},
            {path.socialWall},
            {path.help},
            {path.factOfTheDay},
            {path.quizQA},
            {path.knowledgeStockArticles},
            {path.knowledgeStock},
            {path.knowledgeStockSingleArticle},
            {path.scoresPage},
            {path.activityDaysEvaluation}
            {path.thanksPage}.
            {path.actionsDay},
            {path.firstAid}, 
            {path.signOfAddiction},
            {path.checkYourself},
            {path.checkYourselfEvaluation},
            {path.contact},
            {path.impression},
            <Route path='*' component={NotFound}/>,
          </Switch>
        )
      }
    </RouterProvider.Consumer>  
  );
};