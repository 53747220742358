
import PouchDB from 'pouchdb';

export default class PouchDbService {
  private static _instance: PouchDbService;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _db: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getInstance(): any {
    if (!PouchDbService._instance) {
      PouchDbService._instance = new PouchDbService();
    }
    return PouchDbService._instance;
  }

  constructor() {
    this._db = new PouchDB('your_db');
  }

  get<T>(filter: string | Map<T, PouchDB.Query.Response<T>> | PouchDB.Filter<T, PouchDB.Query.Response<T>>): Promise<PouchDB.Query.Response<T>> {
    return new Promise((resolve, reject) => {
      this._db.get(filter, (err: unknown, result: PouchDB.Query.Response<T>) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put(data: { [key: string]: string | number | boolean | Array<string | number | boolean> } | PouchDB.Query.Response<any>): Promise<void> {
    return new Promise((resolve, reject) => {
      this._db.put(data, (err: unknown) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }
}