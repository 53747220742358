
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../Quiz.css';
import { ILimitTries } from '../../../Utilities/DbTypes';
import PouchDbService from '../../../Utilities/PouchDbService';

interface QuizQuestionsProps {
  questions: { replyText: string | undefined; replyLetter: string; }[],
  handleSolutionReply: (letter: string, hiddenLetter: string) => void,
  handleMixedLetters: ([letter]: string[]) => void,
  catId: number,
  questionNumber: number
}

const letterBox = ['A','B','C','D']; 
const anotherBox: string[] = [];

const QuizQuestion = ({ 
  questions, 
  catId, 
  handleSolutionReply,
  handleMixedLetters,
  questionNumber}: QuizQuestionsProps): JSX.Element => {

  const [ isFirst, setFirst] = useState<boolean>(true);

  const UpdatedPlayed = async () => {
    const triesLimit: ILimitTries = await PouchDbService.getInstance().get('triesLimit');
     
    triesLimit.limitReacedDate = +new Date();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    triesLimit._rev = triesLimit._rev!;     
    triesLimit.played = triesLimit.played + 1;
    await PouchDbService.getInstance().put(triesLimit);
  };

  
  const question = () => { 
    if(questions){
      questions.map((item, index) =>{
        {anotherBox[index] = item.replyLetter;}
      });
      return(
        <>{
          questions.map((item,index) =>(
            item && <li key={index} className={'singleQuizQuestionItem'} >    
              <NavLink className="menuTextDecoration" to={{ 
                pathname:`/quiz/${catId}`, state:{
                  questionCategory: catId,
                  questionAnsw: item.replyText,
                  questionLetter: item.replyLetter,
                  sampleId: 2}
              }} onClick={() => {handleSolutionReply(letterBox[index], item.replyLetter); getElements();}}>                
                <div style={{display:'flex'}}>
                  <div className="upperItemQuestionNumber">
                    <span style={{paddingRight:'10px'}}>              
                      {letterBox[index]}
                    </span> 
                  </div>
                  <span className="bottomItemQuizContent" style={{textAlign:'left'}}>
                    {
                      item.replyText                              
                    }                
                  </span>
                </div>
              </NavLink>
            </li>  
          ))}
        </>
      );
    }
  };

  const getElements = () => {
    handleMixedLetters([...anotherBox]);
  };
  
  useEffect(() => {  
    
    if(questionNumber == 1 && isFirst){
      UpdatedPlayed();
      setFirst(false);
    }

    getElements();
  }, []);  
  
  
  return( 
    <ul>
      {question()}
    </ul> 
  );

};

export default QuizQuestion;
