
import React, { useEffect, useState } from 'react';
import  { Route, Redirect } from 'react-router-dom';

import { isSecu } from './ApiSecureRoute';

interface IPrivateRoute {
  isMobile: boolean,
  component: React.ComponentType,
  [key: string]: unknown
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PrivateRoute = ({isMobile, component: Component, ...rest}: IPrivateRoute ) => {
  
  const [isSecure, setSecure] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);

  const secureChannel = async () => {
    const resp = await isSecu();
    if(resp.statusCode === undefined) {
      setSecure(false); 
    }
    if (resp.statusCode === 200) {
      setSecure(true);     
    }
    setLoading(false);
  };

  useEffect(() => {
    secureChannel();
  }, []);
  
  if ( isSecure && !isLoading){ 
    return(
      <Route
        {...rest}
        render={({location}: {location: unknown})=>{
          if(isMobile){  
            return <Component/>;
          }
          else { 
            return (
              <Redirect to={{ pathname: '/', state: { from: location }}}/>
            );
          }
        }}
      />
    
    );
  }  else {
    if (!isLoading) {
      window.location.href = '/';
    }
    return (
      null
    );
  }
};

export default PrivateRoute;
 