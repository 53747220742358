
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import commonEn from './Languages/en_us.json';
import commonDe from './Languages/de_de.json';

i18next.use(initReactI18next).init({
  keySeparator: '.',
  interpolation: { escapeValue: false },
  lng: 'de',
  resources: {
    en: {
      translation: commonEn
    },
    de: {
      translation: commonDe
    }
  }
});

export default i18next;
