
import React, { useEffect, useState } from 'react';
import TempImage from '../../../Themes/Assets/Images/H0_Kontakt.svg';
import { FrameLoading } from '../../../Components/FrameLoading';
import {  useHistory, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getZipCode } from './ApiGetContacts';

const Contact = (): JSX.Element => {
  const [ loading, setLoading ] = useState(false);
  const [ zipCode, setZipCode ] = useState('');
  const [ isValid, setIsValid] = useState<boolean>(true);
  const [focused, setFocused] = useState(false);
  const [send, setSend] = useState<boolean>();
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const history = useHistory();
  const { t } = useTranslation();

  const buttonStyle: React.CSSProperties= {    
    width:'50%', 
    height:'20%',
    backgroundColor:'#E6F4F8',
    color: '#0e627d',
    padding: '5px',
    fontWeight:'bold',
    appearance: 'none',
    border:'none'
  };
  const buttonStyleSearch: React.CSSProperties= {    
    width:'40%', 
    height:'20%',
    backgroundColor:'#F9F4F8',
    color: 'black',
    padding: '10px',
    fontWeight:'bold',
    appearance: 'none',
    border:'3px solid #E6EA1D'
  };

  const getContacts = async () => {
    setLoading(true);
    const resp = await getZipCode(zipCode);
    window.location.replace(resp.body?.location !== undefined ? resp.body?.location : '/');
  };

  useEffect(() => {
    if(send === true){
      getContacts();
    }    
  }, [send]);

  useEffect(()=> {
    console.log('.');
  },[focused]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.value.match('^[0-9 ]*$')){
      setZipCode(e.target.value);
      setIsValid(true);
    } else {
      setIsValid(false);
    }  
  };

  const handleSent = () => {
    if(zipCode.length === 5){
      setIsValid(true);      
      setSend(true);
    }
    else{
      setIsValid(false);
    }
  };


  return (
    <div className="quizContainer"> 
      
      <FrameLoading isLoading={loading}>
        < >
          <div className={'topBarMenu'}>
            <img src={TempImage} alt="Fact" style={{padding:'40px 0px 30px'}}/>    
          </div>
          <div style ={{paddingLeft:'30px',width:'90%'}}>
            <span style={{ color:'#F8EA1D', fontWeight:'bold'}}>
              {t('kontaktPage.talkToSomeone.header')}
            </span> 
          </div>
          <ul>
            <div style={{width:'100vw'}}>       
              <div className='singleQuizCategoriesItem' >            
                <span style={{ lineHeight:'16px', wordWrap:'break-word'}}>
                  {t('kontaktPage.talkToSomeone.talkToFriend')} <br /><br />
                  <b>{t('kontaktPage.talkToSomeone.helpline')} </b><br />
                  <span >
                    {t('kontaktPage.talkToSomeone.price')} <br />
                  </span>
                  {t('kontaktPage.talkToSomeone.openOn')} <br /><br />
                  {t('kontaktPage.talkToSomeone.also')}
                  <b>{t('kontaktPage.talkToSomeone.experts')}</b>
                  {t('kontaktPage.talkToSomeone.restText')}
                  <br /><br />
                  <a style={{wordWrap:'break-word'}} 
                    href={`${t('kontaktPage.talkToSomeone.link')}`}>
                    {t('kontaktPage.talkToSomeone.link')}
                  </a>
                </span> 
              </div>
            </div>
            <div className='singleQuizCategoriesItem' 
              style={{display:'flex', 
                flexDirection:'column', 
                textAlign:'center', 
                alignItems:'center', 
                justifyContent:'center', 
                backgroundColor:'#E6F4F8'}}>
                  
              <div style={{marginBottom:'15px'}}>
              Du suchst Hilfe oder möchtest dich zu verschiedenen Themen der Suchtfragen informieren und beraten lassen? Hier findest du die entsprechenden Beratungsstellen in deiner Nähe. Gib einfach deine Postleitzahl ein.
              </div>         
              <div style={{marginBottom:'15px'}}>
                {isValid === true && isValid !== undefined ? <span></span> : <span>{t('kontaktPage.talkToSomeone.wrongZipCode')}</span>}  
              </div>
              <input type="number" onChange={handleChange}
                onBlur={onBlur} onFocus={onFocus}
                style={{width:'50%', 
                  marginBottom:'20px', 
                  textAlign:'center', 
                  border:`3px solid ${focused ? '#EA1D' : '#E6F4F8'}`, 
                  padding:'15px',
                  background:`${isValid ? '#abd8e2' : '#ff000099'}`}}/>
              <button style={ buttonStyleSearch } onClick={handleSent}> {t('kontaktPage.talkToSomeone.searchBtn')} </button>
              
            </div>

          </ul>
          <div style={{justifyContent:'flex-end', display:'flex', paddingRight:'20px', paddingBottom:'20px'}}>
            <button style={ buttonStyle } onClick={() => history.push('/firstAid')} >
              {t('activityDay.goCat')} 
            </button>
          </div>
        </>
      </FrameLoading>
      
    </div>
  );
};

export default withRouter(Contact);
