
import React from 'react';
import './Desktop.css';
import { useTranslation } from 'react-i18next';


const Desktop = (): JSX.Element => {
  const { t } = useTranslation();
  return(
    <div className="container">     
      <div>
        {t('desktop.info')}
      </div>
    </div>
  );
};
export default Desktop;
