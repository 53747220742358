import CommunicationService, { IPaginableResponse, IResponse } from '../../Utilities/CommunicationService/CommunicationService';

export interface ISymptomsList {
  id?: number,
  symptomText?: string,
  isActive?: boolean,
}

export const getSymptoms = async (): Promise<IResponse<IPaginableResponse<ISymptomsList>>> => {
  const URL = '/api/help/symptoms';
  const response = await CommunicationService.getInstance().secureCall<IPaginableResponse<ISymptomsList>>({  
    type:'GET',
    path: URL,
    headers: {
      'cache-control': 'no-cache'
    }
  });
  if(response.error){
    console.log(response.error);
  }
  
  return response;
 
};