
import React, { useEffect, useState } from 'react';
import { FrameLoading } from '../../Components/FrameLoading';
import { IScore } from '../../Utilities/DbTypes';
import PouchDbService from '../../Utilities/PouchDbService';
import Ranking from '../../Themes/Assets/Images/Ranking.svg';
import { getCategories } from '../Quiz/ApiGetCategories';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const ScoresPage = (): JSX.Element => {

  const [ loading, setLoading ] = useState(true);
  const { t } = useTranslation();
  const [ showScore, setShowScore ] = useState<number>();
  const [ categoryNumber, setCategoryNumber ] = useState<number>();
  const maxScore = (categoryNumber || 1)*9;

  const history = useHistory();

  const getRankingLevel = (point:number) => {
    if(point < (maxScore * 0.27)) 
      return t('scorePage.rankingLevel', {X: t('scorePage.Beginner')});

    else if(point > (maxScore * 0.27) && point < (maxScore * 0.55)) 
      return t('scorePage.rankingLevel', {X: t('scorePage.Advanced')});

    else if(point > (maxScore * 0.55) && point < (maxScore * 0.97)) 
      return t('scorePage.rankingLevel', {X: t('scorePage.Professional')});

    else if(point > (maxScore * 0.97)) 
      return t('scorePage.rankingLevel', {X: t('scorePage.Expert')});
  };
  
  const getNextLevel = (point:number) => {
    if(point < (maxScore * 0.27))   
      return  t('scorePage.nextLevel', {X: Math.ceil((maxScore * 0.27) - point), Y:t('scorePage.Advanced')});

    else if(point > (maxScore * 0.27) && point < (maxScore * 0.55)) 
      return t('scorePage.nextLevel', {X: Math.ceil((maxScore * 0.55) - point), Y:t('scorePage.Professional')});

    else if(point > (maxScore * 0.55) && point < (maxScore * 0.97))   
      return t('scorePage.nextLevel', {X: Math.ceil((maxScore * 0.97) - point), Y:t('scorePage.Expert')});
  };

  const buttonStyle: React.CSSProperties = {    
    width:'40vw', 
    color: '#0e627d',
    padding: '5px',
    marginBottom: '20px',
    backgroundColor:'#E6F4F8',
    fontWeight: 900,
    fontSize:'4vw',
    appearance: 'none',
    border:'none'
  };

  let score: IScore;
  const getScoreFromDB = async () => {
    setLoading(true);    
    const resp = await getCategories();
    setCategoryNumber(resp?.body?.data.length);
    try{
      score = await PouchDbService.getInstance().get('allScore');
    } catch (e) {
      score = {
        _id: 'allScore',
        score: 0,
        categoriesNumber: resp?.body?.data.length
      };
      await PouchDbService.getInstance().put(score);
    }
    setShowScore(score.score);
    setLoading(false);    
  };

  useEffect(() => {
    getScoreFromDB();
  }, []);

  return (
    <FrameLoading isLoading={loading}>
      <>
        <div style={{ border: '3px solid yellow', 
          display: 'flex', 
          position: 'relative', 
          flexDirection: 'column',
          backgroundColor: '#0e627d',
          textAlign: 'center',
          height:'85vh',
          margin: '20px 20px',
          justifyContent:'space-between' }}>
          <div className="containerImg">
            <div style={{position:'relative'}}>
              <img alt="StartApp" style={{width:'55vw', marginTop:'20%'}} src={Ranking} />
              <div   style={{
                position:'absolute', 
                top:'40%', 
                left:'25%',
                width:'50%',
                textAlign:'center', fontWeight:'bold', fontSize:'5vw', color:'white'}} >
                <span style={{color:'#F8EA1D', 
                  fontSize:'4.5ch'}}>
                  {showScore}
                </span>
                <br />
                {t('scorePage.yourScore')} 
              </div>
            </div>
          </div>  
          <div className="containerText" style={{marginBottom:'20px', marginLeft: '40px', height:'30%'}}>
            <span className="bigText" style={{fontSize:'6vw'}}> 
              {getRankingLevel(showScore || 0).toUpperCase()}
            </span>
            <hr className="hr"/>
            <span className="smallText yellow" style={{fontSize:'4vw',paddingBottom:'40px'}}>
              {getNextLevel(showScore || 0)}
            </span> 
          </div>
          <div >
            <button  style={buttonStyle} onClick={() => history.push('/quiz')}>
              {t('scorePage.buttonContent')}
            </button>   
          </div>
        </div>
      </>
    </FrameLoading>
  );
};

export default ScoresPage;

