
import { createHmac } from 'crypto';

type TRequestType =  'POST' | 'PUT' | 'GET' | 'OPTIOS' | 'DELETE';

interface ICall {
 type: TRequestType ,
 path: string,
 // eslint-disable-next-line @typescript-eslint/no-explicit-any
 body?: { [key: string]: string | any } | undefined,
 headers?: { [key: string]: string }
}

export interface IResponse<T> {
  body?: T,
  error?: { [key: string]: string } | string,
  rawBody?: string,
  statusCode: number,
}
interface IXAuthToken {
  token: string,
  id: number
}

export interface IPaginableResponse<T> {
  data: T[];
  dataCount: number;
}

class CommunicationService{
  private constructor (){ /* EMPTY */ }
  private static _INSTANCE: CommunicationService;
  public static getInstance(): CommunicationService{
    if(!CommunicationService._INSTANCE){
      CommunicationService._INSTANCE = new CommunicationService;
    }
    return CommunicationService._INSTANCE;
  }

  public parseQuery(obj: {[key: string]: string | number | boolean}): string {
    let result = '';
    if (Object.keys(obj).length !== 0) {
      const size = Object.keys(obj).length;
      result += '?';
      Object.entries(obj).forEach(([key, value]: [string, string | number | boolean], index: number) => {
        result += `${key}=${value}`;
        if (index < size - 1) {
          result += '&';
        }
      });
    }
    return result;
  }

  public async secureCall<T>({ type, path, body, headers }: ICall): Promise< IResponse<T> > {

    const authToken = this.prepareAtuhToken();

    const authHeaders:{ [key: string]: string } = {};
    authHeaders['authorization'] = `Token ${authToken}`;
    Object.entries(headers || {}).forEach( ([key,value]) => {
      authHeaders[key] = value;
    } );
    
    const response:  IResponse<T> = await this.publicCall<T>({type, path, body, headers: authHeaders });      
    
    return response;
  }

  public async publicCall<T>({ type, path, body, headers }: ICall): Promise< IResponse<T> > {
    const response: IResponse<T> = { statusCode: 999 };
    const readyHeaders = new Headers();
    
    let url = `${process.env.REACT_APP_API_URL}${path}`;

    if (!this.isBodyNedded(type) && body ) {
      url += this.parseQuery(body);
    }

    Object.entries(headers || {}).forEach( ([key,value]) => {
      readyHeaders.set(key,value);
    } );

    readyHeaders.set('Content-Type','application/json');
    readyHeaders.set('Accept','application/json');    
    try {
      const result = await fetch(url,{
        method: type,
        headers: readyHeaders,
        body: this.isBodyNedded(type) ? JSON.stringify(body) : undefined
      });
      try {
        response.rawBody = await result.text();
        response.body = (JSON.parse(response.rawBody)) as T;
      } catch(e) {
        /** This console log is for debug purposes */
        console.log('body is not a json!');
      }
      response.statusCode = result.status;

    } catch(error: any){
      response.error = error.message;
    }
    return response;
  }

  private isBodyNedded(type: TRequestType): boolean {
    return [ 'POST','PUT' ].includes(type);
  }

  private getXAuthToken(): IXAuthToken | null {
    const obj = localStorage.getItem('X-Auth-Token');
    if(obj) { 
      const rawToken = atob(obj); 
      return JSON.parse(rawToken);
    } 

    return null;
  }

  private prepareAtuhToken(): string | null {
    const xAuthTokenObj = this.getXAuthToken();  

    if(!xAuthTokenObj) return null;
    const timeStamp: number = Math.floor((+new Date())/1000/60);
    const hashAppToken2: string = createHmac('sha256', `${xAuthTokenObj.token}:${timeStamp}`).digest('hex');

    return hashAppToken2 + ':' + xAuthTokenObj.id;
  }


}

export default CommunicationService;