
import CommunicationService, { IPaginableResponse, IResponse } from '../../Utilities/CommunicationService/CommunicationService';

export interface IQuizCategoryResponse {  
    id?: number,
    categoryName?: string,
    isActive?: boolean,  
    image?: string,
}
const URL = '/api/categories';

export const getCategories = async (): Promise<IResponse<IPaginableResponse<IQuizCategoryResponse>>> => {
  const response = await CommunicationService.getInstance().secureCall<IPaginableResponse<IQuizCategoryResponse>>({  
    type:'GET',
    path: URL,
    headers: {
      'pragma': 'no-cache',
      'cache-control': 'no-cache'
    },
    body: {
      'page': '0',
      'rowsPerPage':'10'
    }
  });
  if (response.error) {
    console.error(response.error);
  }
  
  return response;
  
};