
import CommunicationService from '../../../Utilities/CommunicationService/CommunicationService';

export interface IQuizCorrectQuestionResponse {
  statusCode?: number;
}

export const sendSurvey = async (anwserTab: {questionId: number, answer: string}[], eventId: string): Promise<IQuizCorrectQuestionResponse> => {

  const result: IQuizCorrectQuestionResponse = {};

  const URL = `/api/survey/answers/${eventId}/collect`;
  const response = await CommunicationService.getInstance().secureCall<IQuizCorrectQuestionResponse>({  
    type:'PUT',
    path: URL,
    headers: {
      'pragma': 'no-cache',
      'cache-control': 'no-cache'
    },
    body: { 'surveyAnswers': anwserTab}
  });
  if(response.statusCode === 201 && response.body) { 
    result.statusCode =  response.body?.statusCode;
  } 
  return result;
};