import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TempImage from '../../Themes/Assets/Images/tempImage.png';
import H0_Quiz from '../../Themes/Assets/Images/Top_Quiz_Game.png';
import { getCategories, IQuizCategoryResponse } from './ApiGetCategories';
import { FrameLoading } from '../../Components/FrameLoading';
import { InputContext } from '../../Themes/Providers/InputContext';
import {
  IQuizResolveDocument,
  ILimitTries,
  IScore,
} from '../../Utilities/DbTypes';
import Check from '../../Themes/Assets/Images/Check@3x.png';
import PouchDbService from '../../Utilities/PouchDbService';
import CloseButton from '../../Themes/Assets/Images/Button_Close.svg';

const QuizContent = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const [category, setCategory] = useState<IQuizCategoryResponse[]>();
  const { questionNumber, toggleQuestionNumber } = useContext(InputContext);
  const [loading, setLoading] = useState(true);
  const [dbRes, setDbRes] = useState<Array<IQuizResolveDocument>>();
  const [enable, setEnable] = useState<boolean>(true);
  const [chances, setChances] = useState<number>(18);
  const IsCategoryEnable = !enable ? 'none' : 'auto';

  const buttonStyle: React.CSSProperties = {
    width: '60%',
    height: '20%',
    color: '#0e627d',
    backgroundColor: '#E6F4F8',
    padding: '5px',
    fontWeight: 'bolder',
    appearance: 'none',
    border: 'none',
  };

  const handleChange = () => {
    setEnable(!enable);
  };

  let triesLimit: ILimitTries;

  const getAllCategories = async () => {
    const dbScore: Array<IQuizResolveDocument> = [];
    setLoading(true);
    const resp = await getCategories();

    triesLimit = await PouchDbService.getInstance().get('triesLimit');
    setChances(chances - triesLimit.played);
    if (triesLimit.played >= 18) {
      handleChange();
      history.push('/quiz');
    }
    window.onbeforeunload = () => {
      return false;
    };

    if (resp.body?.data) {
      const respData = resp.body.data;
      setCategory(respData);

      for (const item of respData) {
        let data: IQuizResolveDocument;
        let score: IScore;
        try {
          data = await PouchDbService.getInstance().get(`${item.id}` || 0);
        } catch (e) {
          data = {
            _id: item.id ? `${item.id}` : 'null',
            idCat: item.id || 999,
            isFinished: false,
            numberOfCorrectAnwsers: 0,
          };
          await PouchDbService.getInstance().put(data);
        }
        dbScore.push(data);

        try {
          score = await PouchDbService.getInstance().get('allScore');
        } catch (e) {
          score = {
            _id: 'allScore',
            score: 0,
          };
          await PouchDbService.getInstance().put(score);
        }
      }
      setDbRes(dbScore);
    }
    setLoading(false);
  };

  const handleCount = () => {
    if (questionNumber !== 1) toggleQuestionNumber(1);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const generateQuizCategories = () => {
    if (category && dbRes) {
      return (
        <>
          {category.map((item) => {
            const score = dbRes.find((el) => el.idCat === item.id);
            if (score) {
              return (
                <li
                  key={item.id}
                  style={{ pointerEvents: `${IsCategoryEnable}` }}
                  className={
                    score.numberOfCorrectAnwsers == 9
                      ? 'singleQuizCategoriesItemPass'
                      : 'singleQuizCategoriesItem'
                  }
                >
                  <NavLink
                    className="menuTextDecoration"
                    to={{
                      pathname: `/quiz/${item.id}`,
                      state: { id: item.id, name: item.categoryName },
                    }}
                    onClick={() => {
                      handleCount();
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontWeight: 'bolder',
                          textAlign: 'center',
                          fontSize: 'large',
                        }}
                      >
                        {score.numberOfCorrectAnwsers == 9 ? (
                          <img
                            src={Check}
                            alt="Check"
                            className="checkQuizCategories"
                          />
                        ) : null}
                        <span>
                          {item.categoryName && item.categoryName.toUpperCase()}
                        </span>
                      </div>
                      <span className="bottomItemQuizContent">
                        {!score.isFinished
                          ? 'noch nicht gespielt'
                          : score.numberOfCorrectAnwsers == 9
                          ? `Kategorien Bestanden: ${score.numberOfCorrectAnwsers} von 9`
                          : `Dein bisher bester Versuch: ${score.numberOfCorrectAnwsers} von 9`}
                      </span>
                    </div>
                  </NavLink>
                </li>
              );
            }
            return null;
          })}
        </>
      );
    }
    return null;
  };

  return (
    <FrameLoading isLoading={loading}>
      <div>
        <div className={'topBarQUiz'}>
          <img src={TempImage} alt="Image" className={'quizImage'} />
          <img src={H0_Quiz} alt="Sign_Quiz" className={'quizSign'} />
        </div>
        <div className="quizKatText">
          {chances > 0 ? (
            t('quiz.categories')
          ) : (
            <div
              onClick={() => history.push('/menu')}
              style={{
                backgroundColor: '#1559',
                width: '100%',
                height: '100%',
                margin: 0,
                padding: 0,
                position: 'absolute',
                zIndex: 1,
                top: 0,
                left: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                className="singleQuizQuestionItem"
                style={{
                  width: '70%',
                  height: '80%',
                  textAlign: 'left',
                  display: 'grid',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bolder',
                  fontSize: 'large',
                  backgroundColor: '#0E627D',
                }}
              >
                <img
                  src={CloseButton}
                  alt="Fact"
                  style={{ position: 'relative', top: '-60%', right: '-100%' }}
                />
                <div style={{ position: 'relative', left: '5%', width: '80%' }}>
                  {t('quiz.popUp_1').toUpperCase()} <br />
                  <span style={{ fontWeight: 'normal' }}>
                    {t('quiz.popUp_2')}
                    <br />
                    {t('quiz.popUp_3')}
                  </span>
                </div>
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                  <button style={buttonStyle}>{t('activityDay.goCat')}</button>
                </div>
              </div>
            </div>
          )}

          <span>
            {chances} {chances == 0 ? t('quiz.lastTry') : t('quiz.triesLeft')}
          </span>
        </div>
        <ul>{generateQuizCategories()}</ul>
      </div>
    </FrameLoading>
  );
};
export default QuizContent;
