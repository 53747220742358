
import CommunicationService from '../../../Utilities/CommunicationService/CommunicationService';

export interface IQuizCorrectQuestionResponse {
  isCorrect?: boolean;
  correctAnswer?: string;
  solutionReply?: string;
  solutionSource?: string;
}

export const getCorrectAnwser = async (questionId: number, repleyLetter: string): Promise<IQuizCorrectQuestionResponse> => {

  const result: IQuizCorrectQuestionResponse = {};

  const URL = `/api/questions/validate/${questionId.toString()}`;
  const response = await CommunicationService.getInstance().secureCall<IQuizCorrectQuestionResponse>({  
    type:'POST',
    path: URL,
    headers: {
      'pragma': 'no-cache',
      'cache-control': 'no-cache'
    },
    body: { 'answer': repleyLetter }
  });
  if(response.statusCode === 201 && response.body) { 
    result.isCorrect =  response.body?.isCorrect;
    result.correctAnswer =  response.body?.correctAnswer;
    result.solutionReply =  response.body?.solutionReply;
    result.solutionSource = response.body.solutionSource;
  } 

  return result;
};