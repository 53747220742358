
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import quizImage from '../../Themes/Assets/Images/Icon_Quiz.svg';
import categoriesImage from '../../Themes/Assets/Images/Icon_Knowledge_stock.svg';
import activityImage from '../../Themes/Assets/Images/Icon_Aktionstag.svg';
import helpImage from '../../Themes/Assets/Images/IconErsteHilfe.svg';
// import socialImage from '../../Themes/Assets/Images/Icon_Social_Wall.svg';
import nextButton from '../../Themes/Assets/Images/RightArrow.svg';
import FaktSign from '../../Themes/Assets/Images/Fakt_des_Tages.svg';
import NextYellowBG from '../../Themes/Assets/Images/next_button_yellowBG.png';
import TempImage from '../../Themes/Assets/Images/tempImage.png';
import { FrameLoading } from '../../Components/FrameLoading';
import { getFact, IFactResponse } from './Api';
import { getAviblity } from '../ActivityDay/ApiIsActionsDayAvilable';

const MenuContent = (): JSX.Element => {
  const { t } = useTranslation();

  const [ loading, setLoading ] = useState(true);
  const [ factData, setFactData ] = useState<IFactResponse>();
  const [ elementHeight, setElementHeight ] = useState<number|undefined>();
  const [ isUserPublic , setIsUserPublic ] = useState<boolean>();

  const getFactOftheDay = async () => {
    setLoading(true);
    const resp = await getFact(+new Date());
    const aviblityActivityDay = await getAviblity();
    setIsUserPublic(aviblityActivityDay.body?.isUserPublic);
    setFactData(resp);
    setLoading(false);
  };

  const imageGetted = () => {
    if(factData?.image !== undefined && factData?.image !== null ){
      return factData?.image;
    }    
    return TempImage;
  };


  useEffect(() => {
    getFactOftheDay();
    const size = document.getElementById('textFOTD')?.clientHeight;
    setElementHeight(size);
  }, []);

  const menu = [
    { name: `${t('menu.names.quiz')}`, path: '/quiz', content: `${t('menu.quiz')}`, image:`${quizImage}` },
    { name: `${t('menu.names.categories')}`, path: '/categories', content: `${t('menu.categories')}`, image: `${categoriesImage}` },
    isUserPublic ? null : { name: `${t('menu.names.activityDays')}`, path: '/activityDays', image: `${activityImage}` } , 
    { name: `${t('menu.names.help')}`, path: '/firstAid', image: `${helpImage}` },
    // { name: `${t('menu.names.socialWall')}`, path: '/socialWall', image: `${socialImage}` }
  ];

  const handleImage = () => {
    if(imageGetted() !== TempImage){
      return `${imageGetted()}`;
    } else return TempImage;
  };

  const menuContent = menu.map(item => (
    item !== null ?
      <li key={item.name} className="singleMenuItem" >
        <NavLink className="menuTextDecoration" to={item.path} > 
          <div>
            <div className="upperItemMenuContent">
              <img src={item.image} alt={item.name} />
              <span className="itemName" id="textFOTD">
                {item.name}
              </span> 
              <img className="nextPageButton" src={nextButton} alt={`Next button of ${item.name}`}/>
            </div>
            <span>
              {item.content}
            </span>
          </div>
        </NavLink>
      </li> : null
  ));

  return(
    <FrameLoading isLoading={loading}>
      <>
        <div style={{position:'relative'}}>
          <div style={{position:'relative', display:'flex', height:'35vh',  alignItems:'center', justifyContent:'space-around', backgroundColor:'#000000aa'}}>
            <img src={handleImage() } alt="Fact" style={{maxHeight:'100%'}}/>
            <img src={FaktSign} alt="Sign_Quiz" className={'faktOfTheDaySign'}/>  
          </div>       
          <div style={{padding:0}}>
            <NavLink to="/factOfTheDay">
              <span className={'faktOfTheDayGradient'} 
                style={{textAlign:'left',
                  backgroundImage:'linear-gradient( #00000000 , #00000077 5%,black )', 
                  width:'94%', 
                  paddingLeft:'6%',
                  paddingBottom: '2%' }}>                  
                <span style={{width:'75%', padding:'0',paddingTop:'5px', position:'relative', left:'-15%', color:'#F8EA1D'}}>
                  {t('facts.didYouKnow')} <br /> {factData?.factText} 
                </span>
              </span>
              <img src={NextYellowBG} alt="next" className="faktOfTheDayNextButton" 
                style={{bottom:`calc(${elementHeight}px)`, width:'8vw'}}/>
            </NavLink>
          </div>
        </div>
        <ul style={{paddingBottom:'1px'}}>       
          {menuContent}
        </ul>
      </>
    </FrameLoading>
  );
 
};
export default MenuContent;