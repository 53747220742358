
import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';

import { MainTheme } from './Themes/MainTheme';


const App = (): JSX.Element => {
  return (
    <Router basename={'/'}>

      <MainTheme/>
    
    </Router>
  );
};
export default App;
