import CommunicationService, { IPaginableResponse, IResponse } from '../../../Utilities/CommunicationService/CommunicationService';

export interface ISurveyQuestions {
  id?: number,
  questionText?: string,
  answerOption?:string,
  questionType?: string,
  isRequired?: boolean,
  isActive?: boolean,
}


export const getSurvey = async (eventId: string): Promise<IResponse<IPaginableResponse<ISurveyQuestions>>> => {
  const URL = `/api/survey/questions/${eventId}`;
  const response = await CommunicationService.getInstance().secureCall<IPaginableResponse<ISurveyQuestions>>({  
    type:'GET',
    path: URL,
    headers: {
      'cache-control': 'no-cache'
    }
  });
  if(response.error){
    console.log(response.error);
  }
  
  return response;
 
};