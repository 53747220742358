import CommunicationService, { IResponse } from '../../Utilities/CommunicationService/CommunicationService';

export interface IAvibility {
  eventId?: number,
  isUserPublic?: boolean
}


export const getAviblity = async (): Promise<IResponse<IAvibility>> => {
  const URL = '/api/event/available';
  const response = await CommunicationService.getInstance().secureCall<IAvibility>({  
    type:'GET',
    path: URL,
    headers: {
      'cache-control': 'no-cache'
    }
  });
  if(response.error){
    console.log(response.error);
  }
  
  return response;
 
};