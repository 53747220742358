
import React, { useEffect, useState } from 'react';
import CloseButton from '../../Themes/Assets/Images/Button_Close.svg';
import arrowRight from '../../Themes/Assets/Images/ArrowRight.svg';
import FaktSign from '../../Themes/Assets/Images/Fact_of_the_day.png';
import TempImage from '../../Themes/Assets/Images/tempImage.png';
import { Swipeable, defineSwipe} from 'react-touch';
import { FrameLoading } from '../../Components/FrameLoading';
import { getFact, IFactResponse } from '../Menu/Api';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';


const DailyContent = (): JSX.Element => {
  const { t } = useTranslation();
  const [ loading, setLoading ] = useState(true);
  const [ factData, setFactData ] = useState<IFactResponse>();
  const [ date, setDate ] = useState<number>(+new Date());
  const ONE_DAY = 86400000; 
  const history = useHistory();
  
  const getFactOftheDay = async () => {
    setLoading(true);
    const resp = await getFact(date);
    setFactData(resp);
    setLoading(false);
  };

  const handleDateChange = (direction: string) => {    
    direction === 'forward' ?
      setDate(date + ONE_DAY) : 
      direction === 'backward' ?
        setDate(date - ONE_DAY) : 
        setDate(+new Date());
  };

  const handleImage = () => {
    if(imageGetted() !== TempImage){
      return `${imageGetted()}`;
    } else return TempImage;
  };
  
  useEffect(() => {
    getFactOftheDay();
  }, [date]);
  const imageGetted = () => {
    if(factData?.image !== undefined && factData?.image !== null ){
      return factData?.image;
    }    
    return TempImage;
  };
  const hashTagTab = factData?.hashtag?.replace(RegExp(' ','g'),'').split('#');
  hashTagTab?.shift();

  function b64EncodeUnicode(str: string) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    }));
  }
  const linkTab = factData?.externalLink?.split(' ');
  const openInNewWindow = (link:string) => {
    window.open(link, 'width=200,height=100');
  };
  const showLinks = () => linkTab?.map((item, index) => {
    return(
      <a key={index} onClick={() =>openInNewWindow(item)}>
        {item.slice(0,30) + '... '}
      </a>
    );
  });

  const swipe = defineSwipe({swipeDistance: 50});
  const menuContent = (
 
    <div className="factItem">
      <div className="upperItemFact">
        <span>
          {t('facts.number')}  {factData?.id}  <br />
          {t('facts.didYouKnow')} {factData?.factText}
        </span> 
      </div>
      <span className="bottomItemQuizContent">
        <br />
        {factData?.articleText}      
      </span>
      <span>
        <br />
        { hashTagTab?.map( item => {
          const link = b64EncodeUnicode(item);
          return (
            <NavLink to={{ pathname: `/categories/${link}`, state: {categoryImage: 'none'}}} 
              key={item} 
              style={{textDecoration:'none'}}> 
              {`#${item} `} 
            </NavLink> );
          
        }) }
      </span>
      <span>
        <br />
        {showLinks()}
      </span>
    </div>
  );

  return(
    <FrameLoading isLoading={loading}>
      <>
        <div>
          <Swipeable config={swipe} onSwipeLeft={() => handleDateChange('forward')} onSwipeRight={() =>{handleDateChange('backward');}}>
            <div style={{position:'relative', display:'flex', width:'100%',height:'35vh', alignItems:'center', justifyContent:'center', backgroundColor:'#000000aa'}}>
              <img src={ handleImage() }  alt="Fact"  style={{maxHeight:'100%', maxWidth:'100%'}}/>
              <img src={CloseButton} alt="Fact" onClick={history.goBack} style={{ position:'absolute', top: 15, right:15}}/>    
              <img src={FaktSign} alt="Sign_Quiz" style={{ position:'absolute', top: 45, right:10, width:'40%'}}/>
              <img src={arrowRight} alt="arrowRight" className={'factArrowRight'} onClick={() =>{handleDateChange('forward');}}/>
              <img src={arrowRight} alt="arrowRight" className={'factArrowLeft'} onClick={() => {handleDateChange('backward');}}/>
            </div>
          </Swipeable>

          <ul>       
            {menuContent}
          </ul>
        </div>
      </>
    </FrameLoading>
  );

};
export default DailyContent;
