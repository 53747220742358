
import React from 'react';
import { withRouter } from 'react-router-dom';
import './Quiz.css';
import QuizContent from './QuizContent';


const Quiz = (): JSX.Element => { 

  return(
    <div className="quizContainer">     
      <QuizContent/>
    </div>
  );
};

export default withRouter(Quiz);
