
import React from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import PinkPanter from '../../Themes/Assets/Images/pinkPanter.jpg';
import nextButton from '../../Themes/Assets/Images/RightArrow.svg';
import H0_Erste_Hilfe from '../../Themes/Assets/Images/H0_Erste_Hilfe.svg';
import { useTranslation } from 'react-i18next';

const  FirstAid = (): JSX.Element => {  
  const { t } = useTranslation();

  const ActionDayMenu = [
    { name: `${t('menu.names.signsOfAddiction')}`, path: '/firstAid/signOfAddiction'},
    { name: `${t('menu.names.checkYourselkf')}`, path: '/firstAid/checkYourself'},
    // { name: `${t('menu.names.immediateAid')}`, path: '/activityDays'},
    { name: `${t('menu.names.contact')}`, path: '/firstAid/contact'}
  ];

  const categoriesContent = ActionDayMenu.map(item => (
    <li key={item.name} className="singleMenuItem" >
      <NavLink className="menuTextDecoration" to={item.path} > 
        <div>
          <div className="upperItemMenuContent" >
            <span className="itemName" style={{fontWeight:'bold', fontSize:'x-large'}}>
              {item.name}
            </span> 
            <img className="nextPageButton" src={nextButton} alt={`Next button of ${item.name}`}/>
          </div>
        </div>
      </NavLink>
    </li>
  ));

  return(
    <div className="quizContainer"> 
      <>
        <div>
          <div>
            <div className={'topBarMenu'}>
              <img src={PinkPanter} alt="Fact" className={'menuFOTD'}/>    
              <img src={H0_Erste_Hilfe} alt="Sign_Quiz" className={'quizSign'} style={{top:'10%'}}/>
              <div className={'faktOfTheDayGradient'} 
                style={{backgroundImage:'linear-gradient( #00000000,black)', display:'flex', justifyContent:'end',textAlign:'left', width:'100%'}}>
                <span style={{textAlign:'left', padding:'10px 20px', width:'100%', color:'white', fontWeight:'bolder'}} >
                  {t('firstAid.headerText_1')}
                  <br />
                  <span style={{fontWeight:'normal'}}>
                    {t('firstAid.headerText_2')}
                  </span>
                  <br />
                  {t('firstAid.headerText_3')}
                </span>              
              </div>
            </div>
            <div className="quizKatText">
              {t('quiz.categories')}
            </div>
            <ul style={{textAlign:'center', paddingBottom:'1px'}}>
              {categoriesContent}
            </ul>
          </div>
        </div>
      </>      
    </div>
  );
};

export default withRouter(FirstAid);