
import React, { useEffect } from 'react';
import Ranking from '../../../Themes/Assets/Images/Evaluation_Check.svg';
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom';
import { IEvaluationFilled } from '../../../Utilities/DbTypes';
import PouchDbService from '../../../Utilities/PouchDbService';

const ThanksPage = (): JSX.Element => {
  const { t } = useTranslation();

  const history = useHistory();

  const buttonStyle: React.CSSProperties= {    
    width:'80%', 
    color: '#0e627d',
    padding: '5px',
    backgroundColor:'#E6F4F8',
    fontWeight:'bold',
    appearance: 'none',
    border:'none'
  };

  let evaluationEnable: IEvaluationFilled;
  const setEvalDisable = async () => {
    try{
      const evaluationEnable: IEvaluationFilled = await PouchDbService.getInstance().get('evaluationEnable');
      evaluationEnable.isFilled = true;
      await PouchDbService.getInstance().put(evaluationEnable);
    } catch (e) {      
      evaluationEnable = {
        _id: 'evaluationEnable',
        isFilled: false        
      };
      await PouchDbService.getInstance().put(evaluationEnable);
    }
  };

  useEffect(() => {
    setEvalDisable();
  }, []);

  return (
    <div className='container' style={{margin:'20px 0px'}}>
      <>
        <div className="containerImg" style={{display:'flex',  justifyContent:'center', marginTop:'25px'}}>
          <img src={Ranking} style={{transform:'scale(1.2,1.2)'}}/>
        </div>  
        <div style={{textAlign: 'left',padding:'20px 0px'}}>
          <span className="bigText"> 
            {t('activityDay.completed')} 
          </span>
          <hr className="hr"/>
          <span className="smallText yellow">{t('activityDay.thanks')}</span> 
        </div>
              
        <div style={{justifyContent:'center'}}>
          <button style={ buttonStyle } onClick={() => history.push('/activityDays')} >
            {t('activityDay.goCat')} 
          </button>
        </div>
      </>
    </div>
  );
};
export default withRouter(ThanksPage);
