
import { createContext } from 'react';

export const defaultObject = {
  isInputActive: false,
  qrState: '',
  questionNumber: 1,
  toggleActiveState: (props: boolean): void => console.log('Default Provider ActiveState', props),
  toggleQrState: (props: string): void => console.log('Default Provider QrState', props),
  toggleQuestionNumber: (props: number): void => console.log('Default Provider questionNumber', props),
};

export const InputContext = createContext(defaultObject);