
import React, { useState } from 'react';
import {ISetAnwsers} from './Interfaces';

const Buttons = ({handleAnswers, isRequire, questionId, questionText, answerOption}:ISetAnwsers): JSX.Element => {
  const [ isActive, setIsActive ] = useState<number>(0);
  const [ isEmpty, setIsEmpty] = useState<boolean>(true);

  const buttonRate =[1,2,3,4,5,6];
  const handleClick = (item: React.SetStateAction<number>, index: number) => {
    setIsActive(item); 
    handleAnswers(questionId || 999, range ? range[index].toString() : '999'); 
    setIsEmpty(false); 
    isEmpty ? isRequire && isRequire() : null; 
  };

  const range = answerOption?.split(';');


  const buttonsCreate =
      buttonRate.map((item,index) => (
        <button key={index} style = {{ 
          width:'15%', 
          fontSize:`${isActive === item ? 'x-large' : 'large'}`,
          height:'10vw', 
          marginRight:'5px', 
          marginBottom:'5px',
          transform: `${isActive === item ? 'scale(1.1,1.2)' : 'scale(1,1)'}` ,
          border:`2px solid ${isActive === item ? 'black' : 'black'}`,
          backgroundColor:`${isActive === item ? '#F8EA1D' : '#E6F4F8'}`,
          appearance: 'none' }}
        onClick={() =>{ handleClick(item, index);}}>
          <div>
            {item}
          </div>
        </button>
      ));


  return(
    <div className={'singleQuizQuestionItem'} style={{borderColor: `${isEmpty ? 'red' : '#F8EA1D'}`}}>
      <div>
        <span style={{fontWeight:'bold', fontSize:'large'}}>
          {questionText}  <br />
        </span>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <span>
        1 = {range ? range[0] : null} 
          </span>
          <span >
        6 = {range ? range[range.length - 1] : null}
          </span>
        </div>
      </div>
      <br />
      <div style={{display:'flex', justifyContent:'space-between' }}>      
        {buttonsCreate}
      </div>
    </div>
  );
};

export default Buttons;