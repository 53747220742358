
import CommunicationService, { IPaginableResponse, IResponse } from '../../../Utilities/CommunicationService/CommunicationService';

export interface IImpressions {
  id?: number,
  imageThumbnail?: string,
  isActive?: boolean,
}

export interface ISinglePicture {
  image?: string
}


export const getImpressionPictures = async (): Promise<IResponse<IPaginableResponse<IImpressions>>> => {
  const URL = '/api/event/impressions/';

  const response = await CommunicationService.getInstance().secureCall<IPaginableResponse<IImpressions>>({  
    type:'GET',
    path: URL,
    headers: {
      'cache-control': 'no-cache'
    }
  });
  if(response.error){
    console.log(response.error);
  }

  return response;
 
};

export const getOnePicture = async (impressionId?:number): Promise<IResponse<ISinglePicture>> => {

  const URL = `/api/event/impressions/${impressionId}`;
  const response = await CommunicationService.getInstance().secureCall<ISinglePicture>({  
    type:'GET',
    path: URL,
    headers: {
      'cache-control': 'no-cache'
    }
  });
  if(response.error){
    console.log(response.error);
  }
  
  return response;
};
