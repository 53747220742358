
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink} from 'react-router-dom';
import BlackArrow from '../../../Themes/Assets/Images/Black_Arrow_Right.svg';

interface QuizAnwsers {
  correctAnwsers: number | undefined,
  correctTab: {questionText: string, isCorrect:boolean, explanationText: string}[],
}

const CorrectAnwsers = ({correctAnwsers, correctTab}: QuizAnwsers): JSX.Element => {
  const { t } = useTranslation();
  const [ showAnwsers, setShowAnwsers ] = useState<boolean>(false);
  const [ showExplanation, setShowExplanation ] = useState<number>(-1);

  const handleAnwsersList = () => {
    setShowAnwsers(!showAnwsers);
  };

  const BottomArrow = (
    <img src={BlackArrow} alt="Arrow" style={{
      transform: 'rotate(-90deg)',
      width:'15px'}} 
    onClick={ () => handleAnwsersList() }/>
  );

  const UpperArrow = (
    <img src={BlackArrow} alt="Arrow" style={{
      transform: 'rotate(90deg)',
      width:'15px'}} 
    onClick={ () => handleAnwsersList() }/>
  );
  
  const AnwsersList = correctTab.map((item,index) => (
    <li key={index}  className={item.isCorrect ? 'singleQuizCategoriesItemPass' : 'badAnswerItem'} 
      style={{border:'3px solid #F8EA1D'}}>
      <div >
        <div className={item.isCorrect ? 'upperItemQuizContent' : 'upperItemQuizContentBad'}>
          <span>
            {t('quiz.question')} {index + 1}
          </span> 
        </div>
        <div style={{fontSize:'small', textAlign:'center', color: item.isCorrect ? 'black' : 'white'}}>
          <span >
            {item.questionText}
          </span>   
          <br />       
          { 
            showExplanation === index ? <img src={BlackArrow} onClick={() => setShowExplanation(-1)} alt="Arrow" style={{transform: 'rotate(-90deg)',width:'15px'}} /> 
              :  
              <img src={BlackArrow} alt="Arrow" onClick={() => setShowExplanation(index)} style={{zIndex:999,transform: 'rotate(90deg)',width:'15px'}} />
          }
          {showExplanation === index ? 
            <div style={{paddingTop: '10px', fontWeight:'bolder'}}>
              <span >
                {item.explanationText}
              </span>
            </div> : null}
        </div>
      </div>
    </li>
  ));

  const buttonStyle: React.CSSProperties= {    
    position:'relative', 
    top: '80%', 
    left:'60%', 
    width:'30%', 
    color: '#0e627d',
    backgroundColor:'#E6F4F8',
    padding: '5px',
    fontWeight:'bold',
    appearance: 'none',
    border:'none'
  };

  return(
    <>
      <div className='AnsweredItems' >
        <div className="menuTextDecoration">
          <span style={{fontSize:'large',fontWeight:'bold'}}>
            {t('quiz.lastPageMessage',{X : correctAnwsers !== undefined ? correctAnwsers : 0 })}
          </span>          
        </div>
      </div>
      <div className='AnsweredItems' >
        <div className="menuTextDecoration">
          <div className="upperItemQuizContent">
            <span style={{fontSize:'large',fontWeight:'bold'}}>
              {t('quiz.overview')}
            </span>
            <div style={{paddingLeft:'25vw'}}>
              { showAnwsers ? BottomArrow : UpperArrow }
            </div>    
          </div>
        </div>
      </div>
      { showAnwsers ? <ul> {AnwsersList} </ul> : null} 
      <div>
        <NavLink to={{pathname:'/menu'}} >
          <button style={ buttonStyle }>
            {t('quiz.next')} 
          </button>
        </NavLink>
      </div>
    </>
  );
};

export default CorrectAnwsers;
