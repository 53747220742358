
import React, { useState } from 'react';

interface ISetAnwsers {
 handleAnswers: (questionId: number, anwser: string ) => void,
 isRequire: () => void,
 questionId: number | undefined,
 questionText: string | undefined
}

const Buttons = ({handleAnswers, questionText, questionId, isRequire}: ISetAnwsers): JSX.Element => {
  const [ isActive, setIsActive ] = useState<string>('');
  const [ isEmpty, setIsEmpty] = useState<boolean>(true);

  const buttonRate =['JA','NICHT'];

  const handleClick = (item: React.SetStateAction<string>) => {
    setIsActive(item); 
    handleAnswers(questionId || 999, item.toString()); 
    setIsEmpty(false); 
  };

  const buttonsCreate =
      buttonRate.map((item,index) => (
        <button key={index} style = {{ 
          width:'30%', 
          fontSize:'large',
          height:'10vw', 
          margin:'10px 0px',
          transform: `${isActive === item ? 'scale(1.2,1.2)' : 'scale(1,1)'}` ,
          border:'2px solid black',
          backgroundColor:`${isActive === item ? '#F8EA1D' : '#E6F4F8'}`,
          appearance: 'none' }}
        onClick={() =>{ handleClick(item); isEmpty ? isRequire && isRequire() : null; }}>
          <div>
            {item}
          </div>
        </button>
      ));

  return(
    <div className='singleQuizCategoriesItem' style={{borderColor: `${isEmpty ? 'red' : '#F8EA1D'}`}}>      
      <div>           
        <div className='upperItemMenuContent' 
          style={{padding:'10px', fontWeight:'bolder', fontSize:'large', lineHeight:'100%'}}>                                
          <span >
            {questionText}
          </span> 
        </div>
        <div style={{display:'flex', justifyContent:'space-around' }}>      
          {buttonsCreate}
        </div>
      </div>
    </div>
  );
};

export default Buttons;