import CommunicationService, {
  IResponse,
} from '../../Utilities/CommunicationService/CommunicationService';

interface RegistrationResponse {
  token: string;
  userId: number;
}

export interface IRegistrationResult {
  isCompleted: boolean;
  userId?: number;
  token?: string;
  error?: { [key: string]: string } | string;
}

const url = '/api/register';

export const registerQrCode = async (
  token: string
): Promise<IRegistrationResult> => {
  const result: IRegistrationResult = { isCompleted: false };

  const response: IResponse<RegistrationResponse> =
    await CommunicationService.getInstance().publicCall<RegistrationResponse>({
      type: 'POST',
      path: url,
      body: { qrCode: `${token}` },
    });

  if (!response.error && response.body) {
    result.token = response.body.token;
    result.userId = response.body.userId;
  } else if (response.error && !response.body) {
    result.error = response.error;
  }

  return result;
};
