
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { InputContext } from '../../../Themes/Providers/InputContext';
import { getCorrectAnwser, IQuizCorrectQuestionResponse } from './ApiGetCorrectAnwser';
import Tooltip from '../../../Themes/Assets/Images/IconErsteHilfe.svg';

interface QuizAnwserProps {
  solutionRep: { anwseredLetter: string; hiddenLetter: string; },
  currentQuest: string,
  questionId: number | undefined,
  catId: number ,
  isSteelLoading: boolean,
  mixedLetterTable: string[],
  setIsSteelLoading: (isLoading:boolean) => void,
  setLoading: (isLoading:boolean) => void,
  isCorrect: (is:boolean) => void,
  handleCounter: (isCorrect:boolean, explanationText: string) => void,
  handleFinished: (isFinished:boolean) => void
}

const QuizAnswer = ({  
  solutionRep,
  currentQuest,
  catId,
  questionId,
  isSteelLoading,
  mixedLetterTable,
  setIsSteelLoading,
  setLoading,
  isCorrect,
  handleCounter,
  handleFinished}: QuizAnwserProps): JSX.Element => {
  const { t } = useTranslation();
  const { questionNumber, toggleQuestionNumber } = useContext(InputContext);
  const [ correctAnwser, setCorrectAnwser ] = useState<IQuizCorrectQuestionResponse>();
  const [ sourceVisibility, setSourceVisibility ] = useState(false);
  

  const categoryId = catId;

  const handleCounte = () => {
    toggleQuestionNumber(questionNumber + 1);
  };

  const getAnwser = async () => {
    setLoading(true);   
    setIsSteelLoading(true);  
    const resp = await getCorrectAnwser(questionId ? questionId : 0, solutionRep.hiddenLetter);
    if(resp !== null) {
      setCorrectAnwser(resp);   
      isCorrect( resp.isCorrect || false );
      handleCounter( resp.isCorrect || false, resp.solutionReply || 'None');
    }
    setLoading(false);
    setIsSteelLoading(false); 
  };

  let ifLast = false;

  questionNumber === 9 ? ifLast = true : ifLast = false;
  useEffect(() => {
    getAnwser();
  }, []);

  const anwserClickHandler = () =>{
    if( questionNumber < 9) handleCounte();               
    handleFinished(ifLast);
  };

  const findCorrectLetter = (letter:string): string => {
    if (mixedLetterTable[0] == letter) return 'A';
    else if (mixedLetterTable[1] == letter) return 'B';
    else if (mixedLetterTable[2] == letter) return 'C';
    else if (mixedLetterTable[3] == letter) return 'D';
    return 'Incorect anwser!';
  };

  const openInNewWindow = (link:string) => {
    console.log(link);
    window.open(link, 'width=200,height=100');
  };

  const buttonStyle: React.CSSProperties = { 
    position:'relative', 
    left:'60%', 
    width:'30%', 
    height:'5%',
    color: '#0e627d',
    fontWeight:'bold',
    marginBottom: '10px',
    backgroundColor:'#E6F4F8',
    appearance: 'none',
    border:'none'
  };

  return(
    <>{
      !isSteelLoading &&
        <>
          <div className={solutionRep.hiddenLetter !== correctAnwser?.correctAnswer ? 
            'badAnswerItem' : 
            'goodAnswerItem'}>
            <div className="menuTextDecoration">
              {
                solutionRep.hiddenLetter !== correctAnwser?.correctAnswer ?
                  <span style={{fontSize:'large',fontWeight:'bold'}}>
                    {t('quiz.incorrectAnwser',{X: solutionRep.anwseredLetter})}   <br />
                  </span>: null
              }
              <span style={{fontSize:'large',fontWeight:'bold'}}>
                {t('quiz.correctAnwser')}   <br />
              </span> 
              
              <div className="upperItemQuestionNumber">
                <span>
                  {findCorrectLetter(correctAnwser?.correctAnswer ? correctAnwser?.correctAnswer : 'Z')}
                </span> 
              </div>
              <span className="bottomItemQuizContent" style={{
                display:'block',
                paddingTop:'20px',
                textAlign: 'left'
              }}>
                
                <b>{ currentQuest }</b>       
                <br /><br />
                {correctAnwser?.solutionReply}
                <br /><br />                
                <div style={{display:'flex', 
                  verticalAlign:'center', 
                  flexDirection:'column', 
                  textAlign:'center', 
                  alignItems:'center'}}>
                    
                  <div onClick={() => setSourceVisibility(!sourceVisibility)} >
                    <img src={Tooltip} alt="Informationsquelle anzeigen" style={{ width: '10vw', height: '10vh' }}/>
                  </div>
                  {sourceVisibility ? 
                    <a style={{fontSize: '15px', fontWeight:'bold', paddingBottom:'10px'}} 
                      onClick={() => openInNewWindow(correctAnwser?.solutionSource ? correctAnwser?.solutionSource : '')}>
                      {correctAnwser?.solutionSource}
                    </a> 
                    : null}    
                </div>
                
              </span>
            </div>
          </div>
          <NavLink to={{pathname:`/quiz/${catId}`,         
            state:{ categoryId,
              sampleId: 1, 
              numer: questionNumber, }}} >

            <button onClick={anwserClickHandler} style={ buttonStyle }>
              {t('quiz.next')} 
            </button>
          </NavLink>
        </> }
    </>
  );
};

export default QuizAnswer;
