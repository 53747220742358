
import React, { useEffect } from 'react';
import './Menu.css';
import Logo from '../../Themes/Assets/Images/Logo_SKB.svg';
import { withRouter } from 'react-router-dom';
import MenuContent from './MenuContent';
import { IEvaluationFilled, ILimitTries } from '../../Utilities/DbTypes';
import PouchDbService from '../../Utilities/PouchDbService';

const Menu = (): JSX.Element => {
  const getDateForCompare = (date: Date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;  

  let triesLimit: ILimitTries;
  let evaluationEnable: IEvaluationFilled;
  const getLimit = async () => {
    
    try{
      const evaluationEnable: IEvaluationFilled = await PouchDbService.getInstance().get('evaluationEnable');
      const triesLimit: ILimitTries = await PouchDbService.getInstance().get('triesLimit');
      if (getDateForCompare(new Date(triesLimit.limitReacedDate)) !== getDateForCompare(new Date())) {
        evaluationEnable.isFilled = false;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        evaluationEnable._rev = evaluationEnable._rev!;
        await PouchDbService.getInstance().put(evaluationEnable);
      }
    } catch(e) {
      evaluationEnable = {
        _id: 'evaluationEnable',
        isFilled: false,        
      };
      await PouchDbService.getInstance().put(evaluationEnable);
    }

    try{
      const triesLimit: ILimitTries = await PouchDbService.getInstance().get('triesLimit');
      if (getDateForCompare(new Date(triesLimit.limitReacedDate)) !== getDateForCompare(new Date())) {
        
        triesLimit.limitReacedDate = 0;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        triesLimit._rev = triesLimit._rev!;
        triesLimit.played = 0;
        
        await PouchDbService.getInstance().put(triesLimit);       
      }
    } catch (e) {      
      triesLimit = {
        _id: 'triesLimit',
        played: 0,
        limitReacedDate: 0
      };
      await PouchDbService.getInstance().put(triesLimit);
    }

  };

  useEffect(() => {
    getLimit();
  }, []);

  return(
    <div className='menuContainer'>     
      <div className="menuTopBar"> 
        <img src={Logo} alt="Logo" />
      </div>
      <div className="menuList">
        <MenuContent/>
      </div>

    </div>
  );
};
export default withRouter(Menu);
