
import React, { useEffect, useState } from 'react';
import Buttons from './Button';
import TextField from './TextFieldAnwser';
import H0_Evaluation from '../../../Themes/Assets/Images/H0_Evaluation.svg';
import RadioButtonField from './RadioButton';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectAge from './Select';
import { getSurvey, ISurveyQuestions } from './ApiGetSurvey';
import { FrameLoading } from '../../../Components/FrameLoading';
import { sendSurvey } from './ApiPutAnswers';
import { RouteComponentProps, StaticContext, withRouter } from 'react-router';


const ActivityDayEvaluation = ({location}: RouteComponentProps<{[key: string]: string}, StaticContext,{ actionsDayId: string }>): JSX.Element => {
  const { t } = useTranslation();
  const [ answer, setAnswer ] = useState<{questionId: number, answer: string}[]>([]);
  const [ isRequire, setIsRequire ] = useState<number>();
  const [ isValid, setIsvalid ] = useState<boolean>(true);
  const [ survey, setSurvey ] = useState<ISurveyQuestions[] | null>();
  const [ loading, setLoading ] = useState(true);
  const [ reqCount, setReqCount ] = useState<number>(0);
  const [ isTextValidate, setIsTextValidate ] =  useState<{questionId: number | undefined, answer: boolean}[]>([]);
  const [ textValidation, setTextValidation ] = useState<boolean>(true);
  const history = useHistory();
  const eventId = location.state.actionsDayId;
  const questoinTypes = {
    DATE: 'DATE', 
    AGE: 'AGE', 
    RANGE: 'RANGE', 
    TEXT: 'TEXT', 
    SEX: 'SEX', 
    YESNO: 'YESNO', 
    RADIO: 'RADIO'
  };
  
  const updateItem = (questId: number, answerText: string) => {
    const objIndex = answer.findIndex((obj => obj.questionId == questId));
    const newObject = answer[objIndex];
    const data = { 'questionId': questId, 'answer': answerText };    
    if(answerText !== 'None'){
      if (objIndex === -1){
        setAnswer( () => [...answer, data] );
      }
      else{
        newObject['answer'] = answerText;
        setAnswer([
          ...answer.slice(0,objIndex),
          newObject,
          ...answer.slice(objIndex+1)
        ]
        );
      }}
  };
    
  const updateTextValid = (questId: number  | undefined, answerText: boolean) => {
    const objIndex = isTextValidate.findIndex((obj => obj.questionId == questId));
    const newObject = isTextValidate[objIndex];
    const data = { 'questionId': questId, 'answer': answerText };    
    if(answerText !== undefined){
      if (objIndex === -1){
        setIsTextValidate( () => [...isTextValidate, data] );
      }
      else{
        newObject['answer'] = answerText;
        setIsTextValidate([
          ...isTextValidate.slice(0,objIndex),
          newObject,
          ...isTextValidate.slice(objIndex+1)
        ]
        );
      }}
        
    if (isTextValidate.find(x => x.answer === false) !== undefined){
      setTextValidation(false);
    } else setTextValidation(true); 
        
  };      
      
  const buttonStyle: React.CSSProperties= {    
    width:'40%', 
    padding: '5px',
    color: '#0e627d',
    fontWeight:'bold',
    backgroundColor:'#E6F4F8',
    appearance: 'none',
    border:'none'
  };
      
  const handleIsRequire = () => {
    setIsRequire(isRequire !== undefined ? isRequire + 1 : 1);
  };
      
  const getSurveyForEvaluation = async () => {
    setLoading(true);
    const resp = await getSurvey(eventId.toString());    
    setSurvey(resp.body?.data);    
    setLoading(false);
  };
  
  useEffect(() => {
    getSurveyForEvaluation();
  }, []);
  
  const sendAnswers = async () => {
    await sendSurvey(answer,eventId.toString());
  };
  
  let count = 0;
  const getQuestionsList = () => {
    return(
      survey?.map((item, index) => {
        if(item.isRequired && item.questionType !== questoinTypes.DATE)  count ++;
        if( count > reqCount ) setReqCount(count);
        
        if(item.questionType === questoinTypes.AGE){
          return <SelectAge key={index} handleAnswers={updateItem} questionId={item.id} questionText={item.questionText} isRequire={handleIsRequire}  />;
        }
        else if(item.questionType === questoinTypes.RANGE){
          return <Buttons key={index} handleAnswers={updateItem} questionId={item.id} questionText={item.questionText} isRequire={handleIsRequire}  answerOption={item.answerOption}/>;
        }
        else if(item.questionType === questoinTypes.TEXT){
          return <TextField key={index} validate={updateTextValid} handleAnswers={updateItem} questionId={item.id} questionText={item.questionText} answerOption={item.answerOption}/>;
        }
        else if(item.questionType === questoinTypes.SEX || item.questionType === questoinTypes.YESNO || item.questionType === questoinTypes.RADIO){
          return <RadioButtonField key={index} handleAnswers={updateItem} questionId={item.id} questionText={item.questionText} isRequire={handleIsRequire} answerOption={item.answerOption}/>;
        }
        
        
      })
    );
  };

    
  return(
    <FrameLoading isLoading={loading}>
      <>
        <div className="quizContainer">
        
          <div>
            <div className="quizQuestionsContainer" style={{height:'95vh',width:'99vw'}}>
              <div style={{paddingTop:'0px', paddingLeft:'20px'}}>
                <img src={H0_Evaluation} alt="Sign_Quiz" style={{  display:'flex', padding:'40px 0px'}}/>          
              </div>
          
              <ul style={{paddingBottom:'20px'}}>        
                {getQuestionsList()} 
                <div style={{justifyContent: 'space-around',  display:'flex'}}>
                  <button style={ buttonStyle } 
                    onClick={history.goBack}>
                    {t('quiz.return')} 
                  </button>
                  <button style={ buttonStyle } onClick={() => {
                    if(isRequire === reqCount && textValidation){
                      sendAnswers();
                      history.push('endSurvey');
                    } else {
                      setIsvalid(false);
                    }
                  }} >
                    {t('quiz.next')} 
                  </button>
                </div>            
              </ul>
            </div>
          </div>
          {!isValid ? <div onClick={() => setIsvalid(true)} style={{
            backgroundColor:'#1559', 
            width:'100%', 
            height:'105%', 
            margin: 0, 
            padding: 0, 
            position:'fixed', zIndex:999,
            display:'flex',
            flexDirection:'column',
            justifyContent:'center', 
            alignItems:'center'}}>
            <div className='singleQuizQuestionItem' style={{width:'70%',
              height: '20%', textAlign:'center', fontWeight:'bolder', fontSize:'x-large'}}>
              {t('activityDay.popUp')}
            </div>
          </div> : null}
        </div>
    
      </>
    </FrameLoading>
  );
};

export default withRouter(ActivityDayEvaluation);
