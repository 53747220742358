
import React from 'react';
import { isMobileOnly } from 'mobile-device-detect';
import { createContext } from 'react';
import { Route } from 'react-router-dom';
import Desktop from '../../Screens/Desktop/Desktop';
import WelcomePage from '../../Screens/WelcomePage/WelcomePage';
import Quiz from '../../Screens/Quiz/Quiz';
import Menu from '../../Screens/Menu/Menu';
import PrivateRoute from './PrivateRoute';
import Scanner from '../../Screens/QrCodeScanner/Scanner';
import FactOfTheDay from '../../Screens/Facts/FactOfTheDay';
import SingleArticle from '../../Screens/KnowledgeStock/Articles/SingleArticle';
import ScoresPage from '../../Screens/ScoresPage/ScoresPage';
import QuizQA from '../../Screens/Quiz/Questions&Anwsers/QuizQA';
import KnowledgeStockArticles from '../../Screens/KnowledgeStock/KnowledgeStockArticles';
import KnowledgeContent from '../..//Screens/KnowledgeStock/KnowledgeContent';
import ActivityDay from '../../Screens/ActivityDay/ActivityDay';
import ActivityDayEvaluation from '../../Screens/ActivityDay/Evaluation/Evaluation';
import ThanksPage from '../../Screens/ActivityDay/Evaluation/ThanksPage';
import ActionsDay from '../../Screens/ActivityDay/ActionsDay/ActionsDay';
import FirstAid from '../../Screens/FirstAid/FirstAid';
import FirstAidEvaluation from '../../Screens/FirstAid/CheckYourself/Evaluation';
import SignOfAddiction from '../../Screens/FirstAid/SignOfAddiction';
import CheckYourself from '../../Screens/FirstAid/CheckYourself/CheckYourself';
import Contact from '../../Screens/FirstAid/Contact/Contact';
import Impression from '../../Screens/ActivityDay/Impression/Impression';
import InstallPwa from '../../Screens/InstallPwa/InstallPwa';
import { isInStandalone } from './isStandAlone';

const isInStandaloneMode = isInStandalone();

export const routePath = {
  welcomePage: <Route  path='/'  exact={true} component={ WelcomePage } />,
  scanner: <Route path='/scanner' component={ isMobileOnly ? Scanner : Desktop} />,
  menu: <PrivateRoute path='/menu' isMobile={ isMobileOnly } component={Menu} />,
  quiz: <PrivateRoute path='/quiz' exact isMobile={ isMobileOnly } component={Quiz} />,
  knowledgeStock: <PrivateRoute exact path='/categories' isMobile={ isMobileOnly } component={KnowledgeContent} />,
  knowledgeStockArticles: <PrivateRoute exact path='/categories/:id' isMobile={ isMobileOnly } component={KnowledgeStockArticles} />,
  knowledgeStockSingleArticle: <PrivateRoute path='/categories/:id/:id' isMobile={ isMobileOnly } component={SingleArticle} />,
  activityDays: <PrivateRoute exact path='/activityDays' isMobile={ isMobileOnly } component={ActivityDay} />,
  actionsDay: <PrivateRoute exact path='/activityDays/actionsDay' isMobile={ isMobileOnly } component={ActionsDay} />,
  activityDaysEvaluation: <PrivateRoute path='/activityDays/evaluation' isMobile={ isMobileOnly } component={ActivityDayEvaluation} />,
  firstAid: <PrivateRoute path='/firstAid' exact isMobile={ isMobileOnly } component={FirstAid} />,
  signOfAddiction: <PrivateRoute path='/firstAid/signOfAddiction' isMobile={ isMobileOnly } component={SignOfAddiction} />,
  checkYourself: <PrivateRoute path='/firstAid/checkYourself' isMobile={ isMobileOnly } component={CheckYourself} />,
  contact: <PrivateRoute path='/firstAid/contact' isMobile={ isMobileOnly } component={Contact} />,
  checkYourselfEvaluation: <PrivateRoute path='/firstAid/evaluation' isMobile={ isMobileOnly } component={FirstAidEvaluation} />,
  thanksPage: <PrivateRoute path='/activityDays/endSurvey' isMobile={ isMobileOnly } component={ThanksPage} />,
  help: <PrivateRoute path='/help' isMobile={ isMobileOnly } component={Menu} />,
  socialWall: <PrivateRoute path='/socialWall' isMobile={ isMobileOnly } component={Menu} />,
  factOfTheDay:  <PrivateRoute path='/factOfTheDay' isMobile={ isMobileOnly } component={FactOfTheDay} />,
  quizQA: <PrivateRoute path='/quiz/:id' exact isMobile={ isMobileOnly } component={QuizQA} />,
  scoresPage: <PrivateRoute path='/score' isMobile={ isMobileOnly } component={ScoresPage} />,
  impression: <PrivateRoute path='/activityDays/impression' isMobile={ isMobileOnly } component={Impression} />,
};

export const RouterProvider = createContext(routePath);