import React, { useContext, useEffect, useState } from 'react';
import './WelcomePage.css';
import StartKlar from '../../Themes/Assets/Images/Logo_SKB_App.svg';
import { InputContext } from '../../Themes/Providers/InputContext';
import QrScan2LineIcon from 'remixicon-react/QrScan2LineIcon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { registerQrCode } from './Api';
import { FrameLoading } from '../../Components/FrameLoading';

const WelcomePage = (): JSX.Element => {
  const { t } = useTranslation();
  const { toggleQrState, qrState } = useContext(InputContext);
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const LoginToken = localStorage.getItem('X-Auth-Token');
  const [loading, setLoading] = useState(false);

  const sendRegRequest = async (code: string) => {
    setLoading(true);
    const response = await registerQrCode(code);
    setLoading(false);
    if (response.token !== undefined) {
      localStorage.setItem(
        'X-Auth-Token',
        btoa(
          JSON.stringify({
            token: response.token,
            id: response.userId,
          })
        )
      );

      history.push('/menu');
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      toggleQrState(code);
      setTimeout(() => sendRegRequest(code), 200);
    }
  }, [window.location]);

  const history = useHistory();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    toggleQrState(event.target.value);
  };

  const handleQrScanner = () => {
    history.push('/scanner');
  };

  useEffect(() => {
    if (LoginToken) {
      history.push('/menu');
    }
  }, [LoginToken]);
  return (
    <FrameLoading className="container" isLoading={loading}>
      <div
        style={{
          width: '70vw',
          height: '90vh',
          marginLeft: '-5%',
          position: 'absolute',
          display: 'contents',
        }}
      >
        <>
          <div className="containerImg">
            <img
              className="containerLogo"
              src={StartKlar}
              alt="StartApp"
              style={{
                height: `${focused ? '40vh' : '30vh'}`,
                transform: `${
                  focused ? 'translate(-50%, -50%)' : 'translate(0%, 0%)'
                }`,
                position: `${focused ? 'absolute' : 'inherit'}`,
                top: '25%',
                left: '50%',
              }}
            />
          </div>
          {!focused ? (
            <div className="containerText">
              <span className="bigText">{t('welcomePage.welcome')}</span>
              <hr className="hr" />
              <span className="smallText yellow">{t('welcomePage.info')}</span>
            </div>
          ) : null}

          <div className="containerBtnInp">
            {!focused ? (
              <span className="inputText smallText">
                {t('welcomePage.input')}
              </span>
            ) : null}
            <div className="qrCode">
              <input
                className="inputHomePage"
                type="text"
                onFocus={onFocus}
                onBlur={onBlur}
                value={qrState}
                onChange={handleChange}
              />

              <button
                className="qrButton"
                style={{ backgroundColor: '#E6F4F8', appearance: 'none' }}
                onClick={handleQrScanner}
              >
                <QrScan2LineIcon />
              </button>
            </div>
            <button
              className="HP_Button"
              style={{ backgroundColor: '#E6F4F8', appearance: 'none' }}
              onClick={() => sendRegRequest(qrState)}
            >
              {t('welcomePage.button')}
            </button>
          </div>
        </>
      </div>
    </FrameLoading>
  );
};
export default WelcomePage;
