
import CommunicationService from '../../Utilities/CommunicationService/CommunicationService';

export interface IFactResponse {
  statusCode: number,
  id?: number,
  factText?: string,
  image?: string,
  articleText?: string,
  externalLink?: string,
  hashtag?: string
}

export const getFact = async (date: number): Promise<IFactResponse> => {

  const result: IFactResponse = { statusCode: 999 };

  const URL = '/api/articles/fact';  
  
  const response = await CommunicationService.getInstance().secureCall<IFactResponse>({  
    type:'GET',
    path: URL,
    headers: {
      'pragma': 'no-cache',
      'cache-control': 'no-cache'
    },
    body: {'timestamp' : `${date}`}
  });
  if(response.statusCode === 200 ) { 
    result.id = response.body?.id;
    result.factText = response.body?.factText;
    result.image = response.body?.image;
    result.statusCode = response.statusCode;
    result.articleText = response.body?.articleText;
    result.externalLink = response.body?.externalLink;
    result.hashtag = response.body?.hashtag;
  } 
  return result;
};