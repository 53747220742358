
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FrameLoading } from '../../../Components/FrameLoading';
import { InputContext } from '../../../Themes/Providers/InputContext';
import { getQuestions, IQuizQuestionResponse } from '../ApiGetQuestion';
import QuizAnswer from './QuizAnswer';
import QuizQuestion from './QuizQuestion';
import PouchDbService from '../../../Utilities/PouchDbService';
import { IQuizResolveDocument, IScore } from '../../../Utilities/DbTypes';
import CorrectAnwsers from './CorrectAnwsers';
import { useTranslation } from 'react-i18next';
import { StaticContext, useHistory, useParams } from 'react-router';
import HeaderText from '../../../Utilities/HeaderText';

interface ParamTypes {
  id: string
}

const QuizQA = ({location}: RouteComponentProps<{[key: string]: string}, StaticContext,{ sampleId:number, name:string }>): JSX.Element => {
  const { t } = useTranslation();


  const [ questions, setQuestions ] = useState<IQuizQuestionResponse[]>();
  const [ loading, setLoading ] = useState(true);

  const { id } = useParams<ParamTypes>();

  const { questionNumber } = useContext(InputContext);
  const [ finished, setFinished ] = useState<boolean>(false);
  const [ correctAnwsers, setCorrectAnwsers ] = useState<number>();
  const [ isCorrect, setIsCorrect ] = useState<boolean>();
  const [ correctTab, setCorrectTab ] = useState<{questionText: string, isCorrect:boolean, explanationText: string}[]>([]);
  const [ isSteelLoading, setIsSteelLoading ] = useState<boolean>();
  const [ categoryName, setCategoryName ] = useState<string>();
  const [ solutionReply, setSolutionReply ] = useState<{anwseredLetter: string, hiddenLetter: string}>({'anwseredLetter':'A','hiddenLetter':'A'});
  const [ mixedLetterTable, setMixedLetterTable ] = useState<string[]>([]);
  const currentQuestions: { replyText: string | undefined; replyLetter: string; }[] = [];
  const history = useHistory();
  
  if (questions) {
    const {replyA, replyB, replyC, replyD} = questions[questionNumber - 1];
    const qss = [{'replyText': replyA, 'replyLetter': 'A'}, 
      {'replyText': replyB, 'replyLetter': 'B'}, 
      {'replyText': replyC, 'replyLetter': 'C'}, 
      {'replyText': replyD, 'replyLetter': 'D'}];
    Object.values(qss).forEach(value => {
      if(value.replyText !== null && value.replyText !== 'TBD' ) currentQuestions.push(value);
    });
  }

  const sertedQuestions = currentQuestions.sort(function(){ return (Math.random() - 0.5); });

  const currentQuestion = questions ? questions[questionNumber-1].questionText : null;

  let check = 1;

  const handleCategoryName = () => {
    setCategoryName(location.state.name);
  };

  const handleCorrectAnwsers = (isCorrect:boolean, explanationText: string) => {
    if(isCorrect){
      setCorrectAnwsers( !correctAnwsers ? 1 : correctAnwsers + 1);
    }
    const data = {'questionText': currentQuestion ? currentQuestion : '', isCorrect, 'explanationText': explanationText};
    setCorrectTab(() => [...correctTab, data]);
  };

  const handleIsCorrect = (isCorrect:boolean) => {
    if(isCorrect){
      setIsCorrect(true);      
    }else{
      setIsCorrect(false);
    }
  };
  const handleIsFinished = (isFinished:boolean) => {
    if(isFinished){
      setFinished(true);
    }
  };

  const handleLoading = (isLoading:boolean) => {
    setLoading(isLoading);
  };

  
  const handleMixedLetters = (letter: string[]) => {
    setMixedLetterTable(() => [...letter]);
  };

  const handleSolutionReply = (letter:string, hiddenLetter: string) => {
    const reply ={'anwseredLetter' : letter, 'hiddenLetter' : hiddenLetter};
    setSolutionReply(reply);
  };

  const updateQuestionsDB = async () => {
    setLoading(true);    

    const triesLimit = await PouchDbService.getInstance().get('triesLimit');
    if(triesLimit.played >= 18 && finished != true){      
      history.push('/quiz');
    }

    const resp = await getQuestions(id);
    if(resp !== null) {
      setQuestions(resp);   
      const rec: IQuizResolveDocument = await PouchDbService.getInstance().get(`${id}`);
      const setScore: IScore = await PouchDbService.getInstance().get('allScore');
      if (rec && finished && rec.numberOfCorrectAnwsers < (correctAnwsers || 0)) {        
        setScore.score = setScore.score + (correctAnwsers || 0) - rec.numberOfCorrectAnwsers;
        rec.numberOfCorrectAnwsers = correctAnwsers || 0;
        rec.isFinished = finished;
        await PouchDbService.getInstance().put(rec);
        await PouchDbService.getInstance().put(setScore);
      } 
      else {
        setScore.categoriesNumber =  resp?.length || 0;
        await PouchDbService.getInstance().put(setScore);
      }      
    }
    setLoading(false);
  };
  
  if(location.state.sampleId) check = location.state.sampleId;
  useEffect(() => {
    updateQuestionsDB();
    if(finished == false) handleCategoryName();
    
  }, [finished]);

  return(
    <FrameLoading isLoading={loading}>      
      <div className="quizQuestionsContainer" style={{height:'90vh',width:'99vw'}}>
        <div style={{paddingTop:'0px',paddingLeft:'20px'}}>
          <HeaderText text={categoryName || 'Temporary Name'}/>
        </div>
        { finished ?  
          <div className="quizCountQuestion" style={{paddingTop: '40px', paddingLeft: '40px'}}>
            {t('quiz.evaluation')}
          </div>:
          <div className="quizCountQuestion" style={{paddingTop: '40px', paddingLeft: '20px'}}>
            {t('quiz.questionOf',{Number: questionNumber})}  9
          </div> }
        <div className="quizKatText">
          {!isSteelLoading ?
            finished ? 
              <div style={{fontSize:'x-large',fontWeight:'bold',color:'white',paddingLeft:'20px'}}>
                {t('quiz.keepItUp')}
              </div> : check === 1 ?
                currentQuestion 
                : isCorrect ? 
                  <div style={{fontSize:'x-large',fontWeight:'bold',color:'white',paddingLeft:'0px'}}>
                    {t('quiz.correct')}
                  </div> :
                  <div style={{fontSize:'x-large',fontWeight:'bold',color:'white',paddingLeft:'0px'}}>
                    {t('quiz.false')}
                  </div>
            : null }
        </div>
        { 
          finished ? 
            <CorrectAnwsers 
              correctTab={correctTab}  
              correctAnwsers={correctAnwsers} /> :
            check === 1 ?
              <QuizQuestion 
                questions={sertedQuestions} 
                catId={parseInt(id,10)} 
                handleSolutionReply={handleSolutionReply}
                handleMixedLetters={handleMixedLetters}
                questionNumber={questionNumber}/> 
              :
              <QuizAnswer   
                mixedLetterTable = {mixedLetterTable}   
                isSteelLoading={isSteelLoading || false}
                setIsSteelLoading={setIsSteelLoading}
                currentQuest={currentQuestion || 'We have some problem'}
                catId={parseInt(id,10)} 
                solutionRep={solutionReply || 'Error'}    
                questionId={ questions ? questions[questionNumber-1].id : 0}      
                setLoading={handleLoading}
                isCorrect={handleIsCorrect}
                handleCounter={handleCorrectAnwsers} 
                handleFinished={handleIsFinished}  />
        }
      </div>
    </FrameLoading>
  );
};

export default withRouter(QuizQA);
