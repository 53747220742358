
import React, { useContext, useEffect, useState } from 'react';
import { InputContext } from '../../../Themes/Providers/InputContext';
import {ISetAnwsers} from './Interfaces';

const TextField = ({handleAnswers, questionId, questionText, validate}:ISetAnwsers): JSX.Element => {
  const [commentText,setCommentText] = useState('None');
  const [isValid, setIsValid] = useState(true);
  const { toggleActiveState } = useContext(InputContext);
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  useEffect(() => {
    handleAnswers(questionId || 999, commentText);
    toggleActiveState(focused);
  }, [commentText,focused]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if(e.target.value.match('^[a-zA-Z0-9äöüßÄÖÜẞ€.,!?/#!%$~=\r\n ]*$') != null){
      setCommentText(e.target.value);
      setIsValid(true);
      validate && validate(questionId, true);
    } else { 
      setIsValid(false);
      validate && validate(questionId, false);
    }
  };    
  
  return(
    <div className={'singleQuizQuestionItem'}>
      <div>
        <span style={{fontWeight:'bold', fontSize:'large'}}>
          {questionText}  <br />
        </span>
      </div>
      <br />
      <div style={{marginBottom:'5px'}}>
        {isValid ? <span></span> : <span> Verbotene Zeichen ! </span>}  
      </div>
      <div style={{display:'flex'}}>    
        <textarea onBlur={onBlur} onFocus={onFocus} style={{width:'100%',  height:'100px', border:'none', 
          background:`${isValid ? '#abd8e2' : '#ff000099'}`, padding: '15px'}} 
        onChange={handleChange} />
      </div>
    </div>
  );
};

export default TextField;
