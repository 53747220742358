import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { FrameLoading } from '../../../../Components/FrameLoading';
import TempImage from '../../../../Themes/Assets/Images/H0_Check_Yourself.svg';



const YouHaveProblem = (): JSX.Element => {

  const { t } = useTranslation();
  const history = useHistory();
  
  const buttonStyle: React.CSSProperties = {    
    width:'60vw', 
    color: '#0e627d',
    padding: '5px',
    backgroundColor:'#E6F4F8',
    marginBottom: '20px',
    fontWeight: 900,
    fontSize:'3.5vw',
    appearance: 'none',
    border:'none'
  };

  return(
    <>
      <div className="quizContainer"> 
        <FrameLoading isLoading={false}>
          <>
            <div className={'topBarMenu'}>
              <img src={TempImage} alt="Fact" style={{padding:'40px 0px 30px'}}/>    
            </div>
            <ul >
              <div >                
                <div>
                  <span style={{paddingLeft:'30px', color:'#F8EA1D', fontWeight:'bolder'}}>
                  AUSWERTUNG
                  </span> <br />
                  <span style={{paddingLeft:'30px', color:'#CAE7F2', fontSize:'3.5vh',fontWeight:'bold',marginTop:'20px'}}>
                    {t('firstAid.survey.someProblem')}
                  </span>
                  <div className='singleQuizCategoriesItem' style={{backgroundColor:'#D90000', border:'none', color:'white', padding:'20px'}}>                     
                    <span >
                      {t('firstAid.survey.someProblemText')}
                    </span> 
                  </div>
                </div>
              </div>         
            
            </ul>
            <div style={{justifyContent:'flex-end', display:'flex', paddingRight:'20px'}}>
              <button style={ buttonStyle } onClick={() => history.push('/firstAid/checkYourself')} >
                {t('activityDay.goCat')} 
              </button>
            </div>
          </>
        </FrameLoading>
      </div>
    </>
  );
};

export default YouHaveProblem;