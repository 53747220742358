
import CommunicationService, { IPaginableResponse, IResponse } from '../../../Utilities/CommunicationService/CommunicationService';

export interface ICheckYourself {
  id?: number,
  questionText?: string,
  isActive?: boolean,
}


export const getEvaluation = async (): Promise<IResponse<IPaginableResponse<ICheckYourself>>> => {
  const URL = '/api/evaluation/questions';
  const response = await CommunicationService.getInstance().secureCall<IPaginableResponse<ICheckYourself>>({  
    type:'GET',
    path: URL,
    headers: {
      'cache-control': 'no-cache'
    }
  });
  if(response.error){
    console.log(response.error);
  }
  
  return response;
 
};